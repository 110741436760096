import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { actions } from "./../reducers/reducer";

import theme from "./../theme.js";

import ImageCut from "./../images/cards/cut.jpg";
import ImageCardistry from "./../images/cards/cardistry.png";
import ImageShuffle from "./../images/cards/shuffle.png";
//import ImageFlourish from "./../images/cards/flourish.jpg"; 

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  card_root: {
//    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  card_text: {
    textAlign: 'left',
    marginTop: '10px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  video_wrapper: {
    position: 'relative',
    paddingBottom: '56.25%', /* 16:9 */
    marginTop: '10px',
    height: 0
  },
  video_wrapper_iframe: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%'
  },
};

class CardsCardistry extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
  }


  renderCuts = () => {
    return (
      <Card className={this.props.classes.card_root}>
        <CardMedia
          className={this.props.classes.media}
          image={ImageCut}
          title="One Hand Cuts"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            One Hand Cuts
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <div className={this.props.classes.card_text}>
              Learning how to cut a deck of cards with one hand is an impressive move which will help you gain confidence and also encourage greater finger dexterity.  It's also an easy thing to practice as you are watching TV, reading, or other tasks which don't require both hands or intense physical focus.  
            </div>
            <div className={this.props.classes.card_text}>
              <b>Charlier Cut</b> <br/>This is the most basic of all one hand cuts which will teach basic grips, and should be fairly easy for adult size hands.  Tip: For smaller hands, try using a bridge deck.  They aren't as wide and will allow for easier passing of he cards past each other.
            </div>
            <div className={this.props.classes.video_wrapper}>
              <iframe className={this.props.classes.video_wrapper_iframe} width="560" height="315" src="https://www.youtube.com/embed/BNC_DD9XccI?start=28" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className={this.props.classes.card_text}>
              <b>Revolution Cut</b> <br/>This one requires a little more finger flexibility and is a good next step.  Make sure you are practicing this with both your left and your right hand.  You may find it easier with one over the other, but make sure you get smooth with both.
            </div>
            <div className={this.props.classes.video_wrapper}>
              <iframe className={this.props.classes.video_wrapper_iframe} width="560" height="315" src="https://www.youtube.com/embed/6Py19eEfhhs?start=288" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className={this.props.classes.card_text}>
              <b>Scissor Cut</b> <br/>For me this one was actually easier to master than the revolution cut since it seemed like I was in greater control of the cards throughout the motion.  Give it a try.
            </div>
            <div className={this.props.classes.video_wrapper}>
              <iframe className={this.props.classes.video_wrapper_iframe} width="560" height="315" src="https://www.youtube.com/embed/6Py19eEfhhs?start=605" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className={this.props.classes.card_text}>
              <b>Triple Cut</b> <br/>Now that we know a few of the basic cuts, we can start combining them into more complex motions.  Take a look at the triple cut and how it incorporates the revolution cut along with a third section of cards into a fun motion.  
            </div>
            <div className={this.props.classes.video_wrapper}>
              <iframe className={this.props.classes.video_wrapper_iframe} width="560" height="315" src="https://www.youtube.com/embed/ChZa2gosRuE?start=412" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </Typography>
        </CardContent>
      </Card>
    )
  }

  renderShuffles = () => {
    return (
      <Card className={this.props.classes.card_root}>
        <CardMedia
          className={this.props.classes.media}
          image={ImageShuffle}
          title="Shuffles"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Shuffling Cards
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <div className={this.props.classes.card_text}>
              <b>Table Riffle Shuffle</b> <br/>This is a basic shuffle for poker.  It helps conceal the bottom card from view which other more typical shuffling mostions may reveal accidentally.  
            </div>
            <div className={this.props.classes.video_wrapper}>
              <iframe className={this.props.classes.video_wrapper_iframe} width="560" height="315" src="https://www.youtube.com/embed/Oc3P5IfaghY?start=8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className={this.props.classes.card_text}>
              <b>Faro Shuffle</b> <br/>This cut is actually practical in making sure that pairs are split during the shuffling process after a hand.  It also comes into play in card tricks where you are trying to precisely control the position of cards while appearing to mix them up.
            </div>
            <div className={this.props.classes.video_wrapper}>
              <iframe className={this.props.classes.video_wrapper_iframe} width="560" height="315" src="https://www.youtube.com/embed/RXhNA0xLRgY?start=92" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </Typography>
        </CardContent>
      </Card>
    )
  }

  renderCardistry = () => {
    return (
      <Card className={this.props.classes.card_root}>
        <CardMedia
          className={this.props.classes.media}
          image={ImageCardistry}
          title="Cardistry"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Cardistry
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <div className={this.props.classes.card_text}>
              Advanced cardistry shows off some of the most impressive flourishes, requiring great skills, and much practice.  If you truly want to find the beauty in the art, practice some of these moves.
            </div>
            <div className={this.props.classes.card_text}>
              <b>Fan Cards</b> <br/>This one takes a while to get good at.  The condition of the cards matter.  There are subtle finger positons and motions that make this happen.  Some great tips in the video which might require a couple views.
            </div>
            <div className={this.props.classes.video_wrapper}>
              <iframe className={this.props.classes.video_wrapper_iframe} width="560" height="315" src="https://www.youtube.com/embed/2hzkmBSftK4?start=32" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className={this.props.classes.card_text}>
              <b>Card Spring</b> <br/>The card spring just looks cool.  This is one of those moves that has no other purpose but to show off.  So you should definitely learn it ;)  Video shows the concept, just muscle memory and practice from there.  
            </div>
            <div className={this.props.classes.video_wrapper}>
              <iframe className={this.props.classes.video_wrapper_iframe} width="560" height="315" src="https://www.youtube.com/embed/avoKr-mvfzI?start=122" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </Typography>
        </CardContent>
      </Card>
    )
  }

  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <div className={this.props.classes.root}>
            <Grid container spacing={1}>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} md={4}>
                    {this.renderCuts()} 
                </Grid>
                <Grid item xs={12} md={4}>
                    {this.renderShuffles()}
                </Grid>
                <Grid item xs={12} md={4}>
                    {this.renderCardistry()}          
                </Grid>
              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      </div>
    ) 
  }
}

CardsCardistry.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
});

export const CardsCardistryContainer = connect(mapStateToProps, actions)(CardsCardistry);

export default withStyles(styles)(withMediaQuery('(max-width:600px)')(CardsCardistryContainer));
