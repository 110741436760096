// FETCH = make api call to get data
// STORE = save data to the store
// GET = pull data out of the store

export const FETCH_SPACE_DATA = "FETCH_SPACE_DATA";
export const STORE_SPACEX_DATA = "STORE_SPACEX_DATA";
export const STORE_SPACEXLAND_DATA = "STORE_SPACEXLAND_DATA";
export const STORE_ROCKETLAUNCHLIVE_DATA = "STORE_ROCKETLAUNCHLIVE_DATA";
export const STORE_SPACETABSELECTED = 'STORE_SPACETABSELECTED';
export const STORE_MLBRTABSELECTED = 'STORE_MLBRTABSELECTED';
export const STORE_MLBNUMBERSTABSELECTED = 'STORE_MLBNUMBERSTABSELECTED';
export const STORE_BLOGTABSELECTED = 'STORE_BLOGTABSELECTED';
export const STORE_METABSELECTED = 'STORE_METABSELECTED';
export const STORE_CARDSTABSELECTED = 'STORE_CARDSTABSELECTED';
export const STORE_PAGESELECTED = 'STORE_PAGESELECTED';
export const STORE_ISDEVELOPMENTSITE = 'STORE_ISDEVELOPMENTSITE';
export const STORE_ISPERSONALSITE = 'STORE_ISPERSONALSITE';
export const STORE_ISBLACKCLOUDSITE = 'STORE_ISBLACKCLOUDSITE';

const initial = {
  spacex: {},
  rocketlaunchlive: {},
  spacexland: [],
  spaceTabSelected: 0,
  mlbrTabSelected: 0,
  mlbnumbersTabSelected: 0,
  blogTabSelected: 0,
  meTabSelected: 0,
  cardsTabSelected: 0,
  pageSelected: null,
  isDevelopmentSite: false,
  isPersonalSite: false,
  isBlackCloudSite: false
};

const ninjareducer = (state = initial, action = null) => {
  switch (action && action.type) {
    case FETCH_SPACE_DATA:
      return state
    case STORE_SPACEX_DATA:
      return {
        ...state,
        spacex : action.payload
      } 
    case STORE_ROCKETLAUNCHLIVE_DATA:
      return {
        ...state,
        rocketlaunchlive : action.payload
      }     
    case STORE_SPACEXLAND_DATA:
      return {
        ...state,
        spacexland : action.payload
      }      
    case STORE_SPACETABSELECTED:
      return {
        ...state,
        spaceTabSelected : action.payload
      }
    case STORE_MLBRTABSELECTED:
      return {
        ...state,
        mlbrTabSelected : action.payload
      }
    case STORE_MLBNUMBERSTABSELECTED:
      return {
        ...state,
        mlbnumbersTabSelected : action.payload
      }
    case STORE_BLOGTABSELECTED:
      return {
        ...state,
        blogTabSelected : action.payload
      }
    case STORE_METABSELECTED:
      return {
        ...state,
        meTabSelected : action.payload
      }
    case STORE_CARDSTABSELECTED:
      return {
        ...state,
        cardsTabSelected : action.payload
      }
    case STORE_PAGESELECTED:
      return {
        ...state,
        pageSelected : action.payload
      }
    case STORE_ISDEVELOPMENTSITE: 
      return {
        ...state,
        isDevelopmentSite : action.payload
      }
    case STORE_ISPERSONALSITE:
      return {
        ...state,
        isPersonalSite : action.payload
      }
    case STORE_ISBLACKCLOUDSITE:
      return {
        ...state,
        isBlackCloudSite : action.payload
      }
    default:
      return state
  }
}

export const selectors = {
  spacexData: state => state.spacex,
  rocketLaunchLiveData: state => state.rocketlaunchlive,
  spacexlandData: state => state.spacexland,
  spaceTabSelected: state => state.spaceTabSelected,
  mlbrTabSelected: state => state.mlbrTabSelected,
  mlbnumbersTabSelected: state => state.mlbnumbersTabSelected,
  blogTabSelected: state => state.blogTabSelected,
  meTabSelected: state => state.meTabSelected,
  cardsTabSelected: state => state.cardsTabSelected,
  pageSelected: state => state.pageSelected,
  isDevelopmentSite: state => state.isDevelopmentSite,
  isPersonalSite: state => state.isPersonalSite,
  isBlackCloudSite: state => state.isBlackCloudSite
};

export const actions = {
  fetchSpaceData: () => ({ type: FETCH_SPACE_DATA }),
  storeSpacexData: val => ({ type: STORE_SPACEX_DATA, payload: val }),
  storeSpacexlandData: val => ({ type: STORE_SPACEXLAND_DATA, payload: val }),
  storeRocketLaunchLiveData: val => ({ type: STORE_ROCKETLAUNCHLIVE_DATA, payload: val }),
  storeSpaceTabSelected: val => ({ type: STORE_SPACETABSELECTED, payload: val }),
  storeMlbrTabSelected: val => ({ type: STORE_MLBRTABSELECTED, payload: val }),
  storeMlbnumbersTabSelected: val => ({ type: STORE_MLBNUMBERSTABSELECTED, payload: val }),
  storeBlogTabSelected: val => ({ type: STORE_BLOGTABSELECTED, payload: val }),
  storeMeTabSelected: val => ({ type: STORE_METABSELECTED, payload: val }),
  storeCardsTabSelected: val => ({ type: STORE_CARDSTABSELECTED, payload: val }),
  storePageSelected: val => ({ type: STORE_PAGESELECTED, payload: val }),
  storeIsDevelopmentSite: val => ({ type: STORE_ISDEVELOPMENTSITE, payload: val }),
  storeIsPersonalSite: val => ({ type: STORE_ISPERSONALSITE, payload: val }),
  storeIsBlackCloudSite: val => ({ type: STORE_ISBLACKCLOUDSITE, payload: val })
};

export default ninjareducer
