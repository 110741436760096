import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { actions } from "./../reducers/reducer";

import theme from "./../theme.js";

import ImageMemorize from "./../images/cards/cardsmemorize.jpg";
import Image52 from "./../images/cards/cards_52.jpg";

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  card_root: {
//    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  card_text: {
    textAlign: 'left',
    marginTop: '10px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  video_wrapper: {
    position: 'relative',
    paddingBottom: '56.25%', /* 16:9 */
    marginTop: '10px',
    height: 0
  },
  video_wrapper_iframe: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%'
  },
  image_cell: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  image: {
    width: '100%'
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff'
  }
};

class CardsMemorization extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
  }


  renderMemorization = () => {
    return (
      <Card className={this.props.classes.card_root}>
        <CardMedia
          className={this.props.classes.media}
          image={ImageMemorize}
          title="52 Card Memorization"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Memorize 52 Cards ... In Order ... Fast
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} md={6}>
                <div className={this.props.classes.video_wrapper}>
    <iframe className={this.props.classes.video_wrapper_iframe} width="560" height="315" src="https://www.youtube.com/embed/G1bkJSxpAUk?start=70" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={this.props.classes.card_text}>
                  I was looking for a challenge, and when I first heard about trying to memorize 52 cards, in order, quickly, that didn't seem very possible.  Then I started watching a few videos about methods to achieve this and decided to give it a try.  
                </div>
                <div className={this.props.classes.card_text}>
                  I started by taking a number of days to assign person, action, object to each card in the deck.  Next I worked to make the recall of each of these fast. Using the <a className={this.props.classes.link} target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Mnemonic_major_system">mnemonic major system</a> really helped accellerate what would otherwise be a daunting challenge.  Once I had practiced and had all the cards down, I moved on to the mind palace assigning a path through my house with specific locations.  I think the fact that I have a photographic memory really helps me with this type of recall.  All the details are in the video to try it yourself.  
                </div>
                <div className={this.props.classes.card_text}>
                  With all this mentally in place, it still seemed unlikely, but I gave it a shot.  Using headphones to block out all noise and distraction, and focus concentration really helps.  On my first try, I missed one card, thats it.  And on my second try, I got it perfect!!!  Now I'm just working on moving faster.  Less time in assignment as I go through the deck, and less mental time reviewing to make it stick.  
                </div>
                <div className={this.props.classes.image_cell}>
                  <img src={Image52} className={this.props.classes.image} alt="52 cards memorized" />
                </div>
                <div className={this.props.classes.card_text}>
                  Best time: 11:17:57
                </div>
              </Grid>
            </Grid>
          </Typography>
        </CardContent>
      </Card>
    )
  }

  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <div className={this.props.classes.root}>
            <Grid container spacing={1}>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={12}>
                    {this.renderMemorization()} 
                </Grid>
              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      </div>
    ) 
  }
}

CardsMemorization.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
});

export const CardsMemorizationContainer = connect(mapStateToProps, actions)(CardsMemorization);

export default withStyles(styles)(withMediaQuery('(max-width:600px)')(CardsMemorizationContainer));
