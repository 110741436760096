import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ____Icon(props) {
    return (
      <SvgIcon {...props} viewBox="0 0 0.578 0.7224999999999999" >
        <path d="M0.578 0.131l0 0.316c0,0.036 -0.015,0.069 -0.039,0.092 -0.023,0.024 -0.056,0.039 -0.092,0.039l-0.316 0c-0.036,0 -0.069,-0.015 -0.092,-0.039 -0.024,-0.023 -0.039,-0.056 -0.039,-0.092l0 -0.316c0,-0.036 0.015,-0.069 0.039,-0.092 0.023,-0.024 0.056,-0.039 0.092,-0.039l0.316 0c0.036,0 0.069,0.015 0.092,0.039 0.024,0.023 0.039,0.056 0.039,0.092zm-0.308 0.3c0.01,0.011 0.026,0.011 0.037,0 0.01,-0.01 0.01,-0.026 0,-0.037l-0.079 -0.079 0.186 0c0.034,0 0.034,-0.052 0,-0.052l-0.186 0 0.079 -0.079c0.01,-0.011 0.01,-0.027 0,-0.037 -0.011,-0.011 -0.027,-0.011 -0.037,0l-0.124 0.123 -0.008 0.019 0.008 0.019 0.124 0.123z" />
      </SvgIcon>
    );
  }
