// 3rd party
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import DrawingIcon from "../images/svg/nounproject/drawing";
import ResumeIcon from "../images/svg/nounproject/resume";
import ProjectIcon from "../images/svg/nounproject/project";

// project redux
import { selectors, actions } from "../reducers/reducer";

// project ui/ux
import theme from "../theme.js";

// project components
import NavBar from "../components/NavBar";
import Body from "../components/Body";
import MeResume from "../components/MeResume";

const styles = {
  root: {
    flexGrow: 1,
  },
  bottomroot: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 1250
  },
  main: {
    alignItems: 'center',
  },
  paragraph: {
    marginLeft: '7%',
    marginRight: '7%',
    color: '#fff',
    fontSize:'10pt',
    textAlign: 'center'
  },
  aboutme_image: {
    width: '70%'
  }
};


class Resume extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <NavBar />
          <div style={{height:115}} />
          <div className={this.props.classes.root}>
            <div className={this.props.classes.main}>
              <div className={this.props.classes.paragraph}>
                <div>
                  <MeResume />
                </div>
              </div>
            </div>
          </div>
          {this.props.mediaQuery &&
            <>
              <div style={{height:90}} />
              <BottomNavigation
                showLabels
                className={this.props.classes.bottomroot}
              >
                <BottomNavigationAction label="Resume" icon={<ResumeIcon />} />
              </BottomNavigation>
            </>
          }
        </ThemeProvider>
      </div>
    )
  }
}

Body.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
});

export const ResumeContainer = connect(mapStateToProps, actions)(Resume);

export default withStyles(styles)(withMediaQuery('(max-width:670px)')(ResumeContainer));
