import React, { PureComponent } from 'react'
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
//import MuiAccountBoxIcon from '@material-ui/icons/AccountBox';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import logobc from './../images/ninjacow_bc_rb.png';
import logosg from './../images/ninjacow_bc_rb.png';
//import logosg from './../images/scott.jpg';
import theme from "./../theme.js";

import ResumeIcon from "./../images/svg/nounproject/resume";

import { selectors, actions } from "./../reducers/reducer";

import resumepdf from "./../documents/scott-gay-resume-04052024.pdf";
import ValidateIPaddress from "./../helperFunctions";

/*
const AccountBoxIcon = withStyles(theme => ({
  root: props =>
    props.color === "white" && props.variant === "contained"
      ? {
          color: theme.palette.white.main,
          "&:hover": {
            backgroundColor: theme.palette.white.dark,
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              backgroundColor: theme.palette.white.main
            }
          }
        }
      : {}
}))(MuiAccountBoxIcon);
*/

const styles = {
  root: {
    flexGrow: 1,
  },
  appbar: {
    opacity: 0.8,
 //   backgroundImage: 'linear-gradient(#000, #000, #000, #000, #000, #444)'
    backgroundColor: '#000',
    zIndex: 1250
  },
  header: {
    width: '100%',
    position: 'absolute'
  },
  logo: {
    position: 'absolute',
    height: '75px',
    marginTop: '12px',
    marginLeft: '40px'
  },
  logo_mobile: {
    height: 90,
    marginTop: 10,
  },

  hamburgericon: {
   height:40,
   width: 40,
  },
  left: {
    width: '10%'
  },
  center: {
    width: '30%',
//    textAlign: 'center',
  },
  right: {
    width: '70%',
    textAlign: 'right'
  },
  buttonlink: {
    ...theme.typography.button,
 //   backgroundColor: theme.palette.background.paper,
    color: '#fff',
    padding: theme.spacing(1),
//    marginLeft: '2%',
    textDecoration: 'none',
    fontFamily: 'Sigmar One',
    fontSize: '10pt',
  },
  buttonlinkSelected: {
    ...theme.typography.button,
 //   backgroundColor: theme.palette.background.paper,
//    color: '#B26037',
    color: '#2976fe',
    padding: theme.spacing(1),
//    marginLeft: '2%',
    textDecoration: 'none',
    fontFamily: 'Sigmar One',
    fontSize: '14pt',
  },

  logo_container: {
    float: 'left',
    backgroundColor: '#000',
    height: '95px',
    width: '150px',
//    position: 'absolute',
//    marginTop: '-15px',
//    marginLeft: '-255px',
    borderRadius: '0px 0px 20px 0px'
  },
//  logo_container_mobile: {
//    width: '100%',
//    textAlign: 'center'
//  },
  link_container: {
//    float: 'right',
//    width: '80%',
    marginTop: '9px',
  },
  float_right: {
    float: 'right',
  }
};

class NavBar extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;    
    let hostname = window.location.hostname.toString();
    if (ValidateIPaddress(hostname))
       this.props.storeIsDevelopmentSite(true);
    if (hostname.indexOf("scottgay") !== -1)
       this.props.storeIsPersonalSite(true);
    if (hostname.indexOf("blackcloud") !== -1)
       this.props.storeIsBlackCloudSite(true);
  }

  handleClick = (event) => {
    this.props.storePageSelected(event.currentTarget);
  };

  handleClose = () => {
    this.props.storePageSelected(null);
  };

  linkToPersonal = () => {
     window.location.href = 'http://www.scottgay.com';
  };

  linkToBlackCloud = () => {
     window.location.href = 'http://blackcloud.ninja';
  };

  handleTopNav = (idx, event) => {
    event.preventDefault();
    if (this.props.location.pathname === '/space') 
      this.props.storeSpaceTabSelected(idx);  
    else if (this.props.location.pathname === '/analysis-pregame')
      this.props.storeMlbrTabSelected(idx);
    else if (this.props.location.pathname === '/the-numbers')
      this.props.storeMlbnumbersTabSelected(idx);
    else if (this.props.location.pathname === '/blog/list')
      this.props.storeBlogTabSelected(idx);
    else if (this.props.location.pathname === '/me')
      this.props.storeMeTabSelected(idx);
    else if (this.props.location.pathname === '/cards')
      this.props.storeCardsTabSelected(idx); 
  };

  renderSpace = () => {
    return (
      <div className={this.props.classes.link_container}>
        <Link href="#" 
          onClick={this.handleTopNav.bind(this, 0)} 
          className={`${this.props.spaceTabSelected === 0 ? this.props.classes.buttonlinkSelected : this.props.classes.buttonlink}`}
        >
          Launches
        </Link>
{/*
        <Link href="#" 
          onClick={this.handleTopNav.bind(this, 1)} 
          className={`${this.props.spaceTabSelected === 1 ? this.props.classes.buttonlinkSelected : this.props.classes.buttonlink}`}
        >
          Companies
        </Link>
*/}
{/*
        <Link href="#" 
          onClick={this.handleTopNav.bind(this, 2)} 
          className={`${this.props.spaceTabSelected === 2 ? this.props.classes.buttonlinkSelected : this.props.classes.buttonlink}`}
        >
          Simulation
        </Link>
*/}
      </div>
    );
  };

  renderMlbr = () => {
    return (
      <div className={this.props.classes.link_container}>
        <Link href="#" 
          onClick={this.handleTopNav.bind(this, 0)} 
          className={`${this.props.mlbrTabSelected === 0 ? this.props.classes.buttonlinkSelected : this.props.classes.buttonlink}`}
        >
          Prediction Engine
        </Link>
        <Link href="#" 
          onClick={this.handleTopNav.bind(this, 1)} 
          className={`${this.props.mlbrTabSelected === 1 ? this.props.classes.buttonlinkSelected : this.props.classes.buttonlink}`}
        >
          Data In Analysis Out
        </Link>
        <Link href="#" 
          onClick={this.handleTopNav.bind(this, 2)} 
          className={`${this.props.mlbrTabSelected === 2 ? this.props.classes.buttonlinkSelected : this.props.classes.buttonlink}`}
        >
          System Design
        </Link>
      </div>
    );
  };
 
  renderMlbnumbers = () => {
    return (
      <div className={this.props.classes.link_container}>
        <Link href="#" 
          onClick={this.handleTopNav.bind(this, 0)} 
          className={`${this.props.mlbnumbersTabSelected === 0 ? this.props.classes.buttonlinkSelected : this.props.classes.buttonlink}`}
        >
          Daily Lineup
        </Link>
        <Link href="#" 
          onClick={this.handleTopNav.bind(this, 1)} 
          className={`${this.props.mlbnumbersTabSelected === 1 ? this.props.classes.buttonlinkSelected : this.props.classes.buttonlink}`}
        >
          2021 Prediction Outcomes
        </Link>
        <Link href="#" 
          onClick={this.handleTopNav.bind(this, 2)} 
          className={`${this.props.mlbnumbersTabSelected === 2 ? this.props.classes.buttonlinkSelected : this.props.classes.buttonlink}`}
        >
          2021 Player Consistency
        </Link>
      </div>
    );
  };

  renderBlog = () => {
    return (
      <div className={this.props.classes.link_container}>
        <Link href="#" 
          onClick={this.handleTopNav.bind(this, 0)} 
          className={`${this.props.blogTabSelected === 0 ? this.props.classes.buttonlinkSelected : this.props.classes.buttonlink}`}
        >
          Blog
        </Link>
      </div>
    );
  };

  renderMe = () => {
    return (
      <div className={this.props.classes.link_container}>
        <Link href="#" 
          onClick={this.handleTopNav.bind(this, 0)} 
          className={`${this.props.meTabSelected === 0 ? this.props.classes.buttonlinkSelected : this.props.classes.buttonlink}`}
        >
          Projects
        </Link>
        <Link href="#" 
          onClick={this.handleTopNav.bind(this, 1)} 
          className={`${this.props.meTabSelected === 1 ? this.props.classes.buttonlinkSelected : this.props.classes.buttonlink}`}
        >
          Art
        </Link>
        <Link href="#" 
          onClick={this.handleTopNav.bind(this, 2)} 
          className={`${this.props.meTabSelected === 2 ? this.props.classes.buttonlinkSelected : this.props.classes.buttonlink}`}
        >
          Resume
        </Link>
      </div>
    );
  };

  renderCards = () => {
    return (
      <div className={this.props.classes.link_container}>
        <Link href="#" 
          onClick={this.handleTopNav.bind(this, 0)} 
          className={`${this.props.cardsTabSelected === 0 ? this.props.classes.buttonlinkSelected : this.props.classes.buttonlink}`}
        >
          Memorization
        </Link>

        <Link href="#" 
          onClick={this.handleTopNav.bind(this, 1)} 
          className={`${this.props.cardsTabSelected === 1 ? this.props.classes.buttonlinkSelected : this.props.classes.buttonlink}`}
        >
          Cardistry
        </Link>
{/*
        <Link href="#" 
          onClick={this.handleTopNav.bind(this, 2)} 
          className={`${this.props.cardsTabSelected === 2 ? this.props.classes.buttonlinkSelected : this.props.classes.buttonlink}`}
        >
          Decks
        </Link>
*/}
      </div>
    );
  };

  render() {
    return(
      <div className={this.props.classes.root}>
        <AppBar position="fixed" className={this.props.classes.appbar}>
          <Toolbar>
            <div className={this.props.classes.center}>  
              {!this.props.mediaQuery &&
                <>
                  <div className={this.props.classes.logo_container}>
                    <Link to="/">
                      {(this.props.isDevelopmentSite || this.props.isBlackCloudSite) && 
                        <img src={logobc} className={this.props.classes.logo} alt='Home'/>
                      }
                      {this.props.isPersonalSite &&
                        <img src={logosg} className={this.props.classes.logo} alt='Home'/>
                      }
                    </Link>
                  </div>
                </>
              }
              {this.props.mediaQuery &&
                <>
                  <div className={this.props.classes.logo_container}>   
                    <Link to="/">
                      {(this.props.isDevelopmentSite || this.props.isBlackCloudSite) &&
                        <img src={logobc} className={this.props.classes.logo} alt='Home'/>
                      }
                      {this.props.isPersonalSite &&
                        <img src={logosg} className={this.props.classes.logo} alt='Home'/>
                      }
                    </Link>
                  </div>
                </>
              }
            </div>
            <div className={this.props.classes.right} >
              <div className={this.props.classes.float_right}>
                <a href={resumepdf} target="_blank" rel="noopener noreferrer">
                  <IconButton aria-label="resume">
{/*                    <AccountBoxIcon color="white" variant="contained" />*/}
                    <ResumeIcon />
                  </IconButton>
                </a>
                <IconButton aria-label="open" aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
                  <MenuIcon color="primary" />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={this.props.pageSelected}
                  keepMounted
                  open={Boolean(this.props.pageSelected)}
                  onClose={this.handleClose}
                >
                  {this.props.isBlackCloudSite &&
                  <>
                    <MenuItem component={Link} to="/analysis-pregame" onClick={this.handleClose}>Analysis Pregame</MenuItem>
                    <MenuItem component={Link} to="/the-numbers" onClick={this.handleClose}>The Numbers</MenuItem>
                    <MenuItem component={Link} to="/blog/list" onClick={this.handleClose}>Blog: 10th Inning</MenuItem>
                    <MenuItem component={Link} onClick={this.linkToPersonal}>scottgay.com</MenuItem>
                  </>
                  }
                  {this.props.isPersonalSite &&
                  <>
                    <MenuItem component={Link} to="/me" onClick={this.handleClose}>Projects</MenuItem>
                    <MenuItem component={Link} onClick={this.linkToBlackCloud}>blackcloud.ninja</MenuItem>
                    <MenuItem component={Link} to="/space" onClick={this.handleClose}>Space</MenuItem>
                    <MenuItem component={Link} to="/cards" onClick={this.handleClose}>Cards</MenuItem>
                  </>
                  }
                  {this.props.isDevelopmentSite &&
                  <>
                    <MenuItem component={Link} to="/analysis-pregame" onClick={this.handleClose}>Analysis Pregame</MenuItem>
                    <MenuItem component={Link} to="/the-numbers" onClick={this.handleClose}>The Numbers</MenuItem>
                    <MenuItem component={Link} to="/blog/list" onClick={this.handleClose}>Blog: 10th Inning</MenuItem>
                    <MenuItem component={Link} to="/me" onClick={this.handleClose}>Projects</MenuItem>
                    <MenuItem component={Link} to="/space" onClick={this.handleClose}>Space</MenuItem>
                    <MenuItem component={Link} to="/cards" onClick={this.handleClose}>Cards</MenuItem>
                  </>
                  }

                </Menu>
              </div>
              {this.props.mediaQuery &&
                <div className={this.props.classes.float_right}>
                  { this.props.location.pathname === '/space' &&
                    this.renderSpace()
                  }
                  { this.props.location.pathname === '/analysis-pregame' &&
                    this.renderMlbr()
                  }
                  { this.props.location.pathname === '/the-numbers' &&
                    this.renderMlbnumbers()
                  }
                  { this.props.location.pathname === '/blog/list' &&
                    this.renderBlog()
                  }
                  { this.props.location.pathname === '/me' &&
                    this.renderMe()
                  }
                  { this.props.location.pathname === '/cards' &&
                    this.renderCards()
                  }
                </div>
              }
            </div>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  const location = useLocation();
  return <Component mediaQuery={mediaQuery} location={location} {...props} />;
};

const mapStateToProps = state => ({
  spaceTabSelected: selectors.spaceTabSelected(state),
  mlbrTabSelected: selectors.mlbrTabSelected(state),
  mlbnumbersTabSelected: selectors.mlbnumbersTabSelected(state),
  blogTabSelected: selectors.blogTabSelected(state),
  meTabSelected: selectors.meTabSelected(state),
  cardsTabSelected: selectors.cardsTabSelected(state),
  pageSelected: selectors.pageSelected(state),
  isDevelopmentSite: selectors.isDevelopmentSite(state),
  isPersonalSite: selectors.isPersonalSite(state),
  isBlackCloudSite: selectors.isBlackCloudSite(state)
});

export const NavBarContainer = connect(mapStateToProps, actions)(NavBar);

export default withStyles(styles)(withMediaQuery('(min-width:671px)')(NavBarContainer));

