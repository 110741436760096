import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ThemeProvider } from "@material-ui/styles";

import Grid from '@material-ui/core/Grid';

import theme from "../../theme.js";

// project redux
import { selectors, actions } from "../../reducers/reducer";

import NavBar from "../../components/NavBar";
// import MlbrResults from "../../components/MlbrResults";
import MlbrSabermetrics from "../../components/MlbrSabermetrics";
import Body from "../../components/Body";

import mlbr_bw from './../../images/mlbr_bw.jpg';
import blog_bw from './../../images/blog_bw.jpg';
import numbers_bw from './../../images/numbers_bw.jpg';
import cards_bw from './../../images/cards_bw.jpg';
import me_bw from './../../images/me-bw.jpg';
import space_bw from './../../images/space_bw.jpg';
import mlbr_color from './../../images/mlbr_color.jpg';
import blog_color from './../../images/blog_color.jpg';
import numbers_color from './../../images/numbers_color.jpg';
import cards_color from './../../images/cards_color.jpg';
import me_color from './../../images/me-color.jpg';
import space_color from './../../images/space_color.jpg';
import github from './../../images/social/github.png';
import twitter from './../../images/social/twitter.png';
import linkedin from './../../images/social/linkedin.png';
import resume from './../../images/social/resume.png';

import resumepdf from "./../../documents/scott-gay-resume-04052024.pdf";
import ValidateIPaddress from "./../../helperFunctions";

const styles = {
  root: {
    flexGrow: 1,
  },
  main: {
    alignItems: 'center',
  },
  paragraph: {
    marginLeft: '7%',
    marginRight: '7%',
    color: '#fff',
    fontFamily: '"Trebuchet MS", Helvetica, sans-serif',
    fontSize:'10pt',
    textAlign: 'center'
  },
  coming_soon: {
    width: '70%'
  },
  grid_element: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  cell: {
    padding: theme.spacing(2),
  },
  image: {
    width: '100%',
    borderRadius: '7px',
    borderStyle: 'solid',
    borderWidth: '5px',
    borderColor: theme.palette.primary.main,
  },
  footerspace: {
    height: '50px'
  },
  social: {
    height: '30px',
  },
  footerbackground: {
    backgroundColor: 'rgba(40, 40, 40, 0.5)',
  },
  headertext: {
    fontSize: '14pt'
  },
  text: {
    color: theme.palette.primary.main,
//    color: '#ffffff',
    fontSize: '20pt',
    fontFamily: 'Sigmar One'
  },
  link: {
    textDecoration: 'none'
  }
};



class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
    let hostname = window.location.hostname.toString();
    if (ValidateIPaddress(hostname))
       this.props.storeIsDevelopmentSite(true);
    if (hostname.indexOf("scottgay") !== -1)
       this.props.storeIsPersonalSite(true);
    if (hostname.indexOf("blackcloud") !== -1)
       this.props.storeIsBlackCloudSite(true);
  }


  render(){
    const { classes } = this.props; 
    return (
      <div>
        <ThemeProvider theme={theme}>
          <NavBar />
          <div style={{height:115}} />
          <div className={classes.root}>
            <div className={classes.main}>
              <div className={classes.paragraph}>
                  <Grid container item xs={12} spacing={1}>
                    { // blackcloud
                    (this.props.isDevelopmentSite || this.props.isBlackCloudSite)  &&
                      <>
                        <Grid item xs={12} className={classes.grid_element}>
                          <div className={classes.headertext}>
                            My name is Scott.  I have a few passions including baseball, engineering, and data science in no particular order.  Pulling together years of experience in all three to build a daily, individual player, MLB prediction model to identify today's likely lineup heros.  
                          </div>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3} className={classes.grid_element} component={Link} to="/analysis-pregame">
                          <div className={classes.cell}>
                            <img src={mlbr_bw} 
                              onMouseOver={e => (e.currentTarget.src = mlbr_color)}
                              onMouseOut={e => (e.currentTarget.src = mlbr_bw)}
                              className={classes.image}  
                              alt="Analysis Pregame" />
                            <div className={classes.text}>
                              Analysis Pregame
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3} className={classes.grid_element} component={Link} to="/the-numbers">
                          <div className={classes.cell}>
                            <img src={numbers_bw}
                              onMouseOver={e => (e.currentTarget.src = numbers_color)}
                              onMouseOut={e => (e.currentTarget.src = numbers_bw)}
                              className={classes.image}
                              alt="The Numbers" />
                            <div className={classes.text}>
                              The Numbers
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3} className={classes.grid_element} component={Link} to="/blog/list">
                          <div className={classes.cell}>
                            <img src={blog_bw}
                              onMouseOver={e => (e.currentTarget.src = blog_color)}
                              onMouseOut={e => (e.currentTarget.src = blog_bw)}
                              className={classes.image}
                              alt="Blog" />
                            <div className={classes.text}>
                              10th Inning
                            </div>
                          </div>
                        </Grid>
                        {/*
                        <Grid item xs={12} md={6} lg={6} className={classes.grid_element}>
                          <MlbrSabermetrics />
                        </Grid>
                        */}
                        <Grid item xs={12} md={3} lg={3} className={classes.grid_element}>
                          <div className={classes.cell}>
                            <a href="http://www.scottgay.com/me" rel="noopener noreferrer" className={classes.link}>
                            <img src={me_bw}
                              onMouseOver={e => (e.currentTarget.src = me_color)}
                              onMouseOut={e => (e.currentTarget.src = me_bw)}
                              className={classes.image}
                              alt="Other Projects" />
                            <div className={classes.text}>
                              Other Projects
                            </div>
                            </a>
                          </div>
                        </Grid>
                      </>
                    }
                    { // personal - me
                    (this.props.isDevelopmentSite || this.props.isPersonalSite)  &&
                      <>
                        <Grid item xs={12} md={3} lg={3} className={classes.grid_element} component={Link} to="/me">
                          <div className={classes.cell}>
                            <img src={me_bw} 
                              onMouseOver={e => (e.currentTarget.src = me_color)}
                              onMouseOut={e => (e.currentTarget.src = me_bw)}
                              className={classes.image}  
                              alt="Me" />
                            <div className={classes.text}>
                              Projects                  
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3} className={classes.grid_element}>
                          <div className={classes.cell}>
                            <a href="http://blackcloud.ninja" rel="noopener noreferrer" className={classes.link}>
                            <img src={mlbr_bw}
                              onMouseOver={e => (e.currentTarget.src = mlbr_color)}
                              onMouseOut={e => (e.currentTarget.src = mlbr_bw)}
                              className={classes.image}
                              alt="blackcloud.ninja" />
                            <div className={classes.text}>
                              blackcloud.ninja
                            </div>
                            </a>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3} className={classes.grid_element} component={Link} to="/space">
                          <div className={classes.cell}>
                            <img src={space_bw} 
                              onMouseOver={e => (e.currentTarget.src = space_color)}
                              onMouseOut={e => (e.currentTarget.src = space_bw)}
                              className={classes.image}  
                              alt="Space" />
                            <div className={classes.text}>
                              Space                  
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3} className={classes.grid_element} component={Link} to="/cards">
                          <div className={classes.cell}>
                            <img src={cards_bw} 
                              onMouseOver={e => (e.currentTarget.src = cards_color)}
                              onMouseOut={e => (e.currentTarget.src = cards_bw)}
                              className={classes.image}  
                              alt="Cards" />
                            <div className={classes.text}>
                              Cards                  
                            </div>
                          </div>
                        </Grid>
                      </>
                    }
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.footerspace}></Grid>
                    <Grid item xs={0} md={1}></Grid>
                    <Grid item xs={12} md={3} className={classes.footerbackground}>
                      <a href="https://mentorcruise.com/mentor/scottgay/" target="_blank" rel="noopener noreferrer"> 
                        <img src="https://cdn.mentorcruise.com/img/banner/navy-sm.svg" width="240" alt="MentorCruise" /> 
                      </a>
                    </Grid>
                    <Grid item xs={4} md={2} className={classes.footerbackground}>
                      <a href="http://www.github.com/blackcloudconcepts" target="_blank" rel="noopener noreferrer">
                        <img src={github}
                          className={classes.social}
                          alt="Github - Black Cloud Concepts" />
                      </a>
                    </Grid>
                    <Grid item xs={4} md={2} className={classes.footerbackground}>
                      <a href="https://twitter.com/blackcloudninja" target="_blank" rel="noopener noreferrer">
                        <img src={twitter}
                          className={classes.social}
                          alt="Twitter - Black Cloud Ninja" />
                      </a>
                    </Grid>
                    <Grid item xs={4} md={2} className={classes.footerbackground}>
                      <a href="https://www.linkedin.com/in/scott-gay-3ba13521/" target="_blank" rel="noopener noreferrer">
                        <img src={linkedin}
                          className={classes.social}
                          alt="Linked In - Scott Gay" />
                      </a>
                    </Grid>
                    <Grid item xs={4} md={2} className={classes.footerbackground}>
                      <a href={resumepdf} target="_blank" rel="noopener noreferrer">
                        <img src={resume}
                          className={classes.social}
                          alt="Resume - Scott Gay" />
                      </a>
                    </Grid>
                  </Grid> 
              </div>
            </div>
          </div>
        </ThemeProvider>
      </div>
    )}
}

Body.propTypes = {
  classes: PropTypes.object.isRequired
};

// export default withStyles(styles)(Home);

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
  isDevelopmentSite: selectors.isDevelopmentSite(state),
  isPersonalSite: selectors.isPersonalSite(state),
  isBlackCloudSite: selectors.isBlackCloudSite(state)
});

export const HomeContainer = connect(mapStateToProps, actions)(Home);

export default withStyles(styles)(withMediaQuery('(max-width:670px)')(HomeContainer));
