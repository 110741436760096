import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector, useStore } from "react-redux";

import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import { selectors, actions } from "./../reducers/reducer";

import theme from "./../theme.js";

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  card_root: {
//    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  card_text: {
    textAlign: 'left'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  video_wrapper: {
    position: 'relative',
    paddingBottom: '56.25%', /* 16:9 */
    marginTop: '10px',
    height: 0
  },
  video_wrapper_iframe: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%'
  },
};

 const renderUpcomingLaunches = (props, rocketLaunchLiveData, spacexlandData) => {
    return (
      <Card className={props.styles.card_root}>
        <CardMedia
          className={props.styles.media}
          image="https://cdn.mos.cms.futurecdn.net/MznHuMznzTSCC8AkPK4nai-1200-80.jpg"
          title="Upcoming Launches"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Upcoming Launches
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <div className={props.styles.card_text}>
              {rocketLaunchLiveData && rocketLaunchLiveData.result && rocketLaunchLiveData.result.map((launch, index) => (
                <div key={index}>
                  <h3>{launch.name}</h3>
                  <p>{launch.launch_description}</p>
                </div>
              ))}
              {rocketLaunchLiveData && !rocketLaunchLiveData.result && 
                <Backdrop className={props.styles.backdrop} open={true}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              }
            </div>
          </Typography>
        </CardContent>
      </Card>
    )
  }

  const renderRecentLaunches = (props, rocketLaunchLiveData, spacexlandData) => {
    return (
      <Card className={props.styles.card_root}>
        <CardMedia
          className={props.styles.media}
          image="https://www.americaspace.com/wp-content/uploads/2021/01/Turksat-5A-1-800x533.jpg"
          title="Recent Launches"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Recent Launches
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <div className={props.styles.card_text}>
              {spacexlandData && spacexlandData.map((launch, index) => (
                <div key={index}>
                  <h3>{launch.mission_name}</h3>
                  <p>{launch.launch_date_utc}</p>
                </div>
              ))}
              {rocketLaunchLiveData && !rocketLaunchLiveData.result && 
                <Backdrop className={props.styles.backdrop} open={true}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              }
            </div>
          </Typography>
        </CardContent>
      </Card>
    )
  }

  const renderStarship = (props, rocketLaunchLiveData, spacexlandData) => {
    return (
      <Card className={props.styles.card_root}>
        <CardMedia
          className={props.styles.media}
          image="https://www.thespacereview.com/archive/4090a.jpg"
          title="SpaceX Starship"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            SpaceX Starship Updates
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
	    <div className={props.styles.video_wrapper}>
              <iframe className={props.styles.video_wrapper_iframe} width="560" height="315" src="https://www.youtube.com/embed/3N7L8Xhkzqo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>

            <div className={props.styles.card_text}>
              Recently Tim Dodd, "The Everyday Astronaut", joined Elon Musk for a tour of the SpaceX Starship launchpad in Boca Chica, Texas.  It was an amazing interview and showed never before seen views into the current state of the program and the layout of the facility.
            </div>
            <div className={props.styles.video_wrapper}>
              <iframe className={props.styles.video_wrapper_iframe} width="560" height="315" src="https://www.youtube.com/embed/t705r8ICkRw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className={props.styles.video_wrapper}>
              <iframe className={props.styles.video_wrapper_iframe} width="560" height="315" src="https://www.youtube.com/embed/SA8ZBJWo73E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className={props.styles.video_wrapper}>
              <iframe className={props.styles.video_wrapper_iframe} width="560" height="315" src="https://www.youtube.com/embed/9Zlnbs-NBUI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>               
          </Typography>
        </CardContent>
      </Card>
    )
  }

export function SpaceLaunch(props){
  const { rocketLaunchLiveData, spacexlandData } = useSelector(state => ({
    rocketLaunchLiveData: state.rocketlaunchlive,
    spacexlandData: state.spacexland	  
  }));
  const store = useStore();
  const dispatch = useDispatch();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

    return (
      <div>
        <ThemeProvider theme={theme}>
          <div className={props.styles.root}>
            <Grid container spacing={1}>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} md={6}>
                    {renderUpcomingLaunches(props, rocketLaunchLiveData, spacexlandData)} 
                </Grid>
	    {/*
                <Grid item xs={12} md={4}>
                    {renderRecentLaunches(props, rocketLaunchLiveData, spacexlandData)}
                </Grid>
		*/}
                <Grid item xs={12} md={6}>
                    {renderStarship(props, rocketLaunchLiveData, spacexlandData)}          
                </Grid>
	    	<button onClick={forceUpdate}>Update me</button>
              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      </div>
	)
}

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

export const SpaceLaunchContainer = () => { return SpaceLaunch({styles})  }
export default withStyles(styles)(withMediaQuery('(max-width:600px)')(SpaceLaunchContainer));
