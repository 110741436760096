import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

//import Backdrop from '@material-ui/core/Backdrop';
//import CircularProgress from '@material-ui/core/CircularProgress';

import { actions } from "./../reducers/reducer";

import theme from "./../theme.js";

const styles = {
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  }, 
};

class SpaceCompanies extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Timeline align="alternate">
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                  September 2000
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                  <FastfoodIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={this.props.classes.paper}>
                  <Typography variant="h6" component="h1">
                    Blue Origin
                  </Typography>
                  <Typography>
                    <Link href="http://www.blueorigin.com" target="_blank" variant="body2">
                      {'www.blueorigin.com'}
                    </Link>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                  May 2002
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <LaptopMacIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={this.props.classes.paper}>
                  <Typography variant="h6" component="h1">
                    SpaceX
                  </Typography>
                  <Typography>
                    <Link href="http://www.spacex.com" target="_blank" variant="body2">
                      {'www.spacex.com'}
                    </Link>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                  June 2006
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" variant="outlined">
                  <HotelIcon />
                </TimelineDot>
                <TimelineConnector className={this.props.classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={this.props.classes.paper}>
                  <Typography variant="h6" component="h1">
                    Rocket Lab
                  </Typography>
                  <Typography>
                    <Link href="http://www.rocketlabusa.com" target="_blank" variant="body2">
                      {'www.rocketlabusa.com'}
                    </Link>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                  December 2006
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="secondary">
                  <RepeatIcon />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={this.props.classes.paper}>
                  <Typography variant="h6" component="h1">
                    United Launch Alliance
                  </Typography>
                  <Typography>
                    <Link href="http://www.ulalaunch.com" target="_blank" variant="body2">
                      {'www.ulalaunch.com'}
                    </Link>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          </Timeline>         
        </ThemeProvider>
      </div>
    ) 
  }
}

SpaceCompanies.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
});

export const SpaceCompaniesContainer = connect(mapStateToProps, actions)(SpaceCompanies);

export default withStyles(styles)(withMediaQuery('(max-width:600px)')(SpaceCompaniesContainer));
