import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// https://react-papaparse.js.org/
import { readRemoteFile } from 'react-papaparse'

import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import ValidateIPaddress from "./../helperFunctions";
//import Button from '@material-ui/core/Button';

import { actions } from "./../reducers/reducer";

import theme from "./../theme.js";
// import selected from "./../data/selected.csv";

const styles = {
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  }, 
  table: {
    minWidth: 650,
  },
  header: {
    marginBottom: '20px',
    fontSize: '12pt',
  }
};

class MlbrResults extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      selectedHeader: [],
      selectedData: [],
      pitcherssalary: "18000"
    }

    this.handleChange= this.handleChange.bind(this);

    let hostname = window.location.hostname.toString();
    if (ValidateIPaddress(hostname))
       this.baseUrl = "http://"+hostname+":"+window.location.port;
    if (hostname.indexOf("scottgay") !== -1)
       this.baseUrl = "http://www.scottgay.com";
    if (hostname.indexOf("blackcloud") !== -1)
       this.baseUrl = "http://"+hostname;
  }

  loadcsv(file) {
    readRemoteFile(file, {
      download: true,
      complete: (results) => {
        this.setState({
          selectedHeader: results.data.shift(),
          selectedData: results.data
        });
      }
    })

  }

  componentWillMount() {
    // get selected csv and parse it
    this.loadcsv(this.baseUrl+'/static/media/18000_selected.csv')
  }

  testDisplay(val) {
//                      <TableCell align="right">{this.testDisplay(row[14])}</TableCell>
    if (parseFloat(val).toFixed(2) === "NaN"){
      return "";
    }
    else {
      return parseFloat(val).toFixed(2);
    } 
  }

  handleChange = (e) => {
    this.setState({
      pitcherssalary: e.target.value
    })
    this.loadcsv(this.baseUrl+'/static/media/'+e.target.value+'_selected.csv');
  };

  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <div>
            <div>
              <Typography variant="body1" className={this.props.classes.header}>
                Today's DraftKings lineup selection (typically updated by 10amCT). <br/>Lineup for the earliest slate, containing the most games for the day.  Select combined cost of pitcher's to determine the optimal lineup.
              </Typography>
            </div>
            <div>
              <Box sx={{ minWidth: 120, maxWidth: 200 }}>
                <FormControl fullWidth>
                  <InputLabel id="select-label">Pitcher's Combined Salary</InputLabel>
                  <Select
                    labelId="select-label"
                    id="select"
                    value={this.state.pitcherssalary}
                    label="Pitchers Salary"
                    onChange={this.handleChange}
                  >
                    <MenuItem value={"16000"}>$16000</MenuItem>
                    <MenuItem value={"16500"}>$16500</MenuItem>
                    <MenuItem value={"17000"}>$17000</MenuItem>
                    <MenuItem value={"17500"}>$17500</MenuItem>
                    <MenuItem value={"18000"}>$18000</MenuItem>
                    <MenuItem value={"18500"}>$18500</MenuItem>
                    <MenuItem value={"19000"}>$19000</MenuItem>
                    <MenuItem value={"19500"}>$19500</MenuItem>
                    <MenuItem value={"20000"}>$20000</MenuItem>
                    <MenuItem value={"20500"}>$20500</MenuItem>
                    <MenuItem value={"21000"}>$21000</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <TableContainer component={Paper}>
              <Table className={styles.table} aria-label="simple table">
                <TableHead>
                  <TableRow key={this.state.selectedHeader[1]}>
                    <TableCell component="th" scope="row">
                      {this.state.selectedHeader[3]}
                    </TableCell>
                    <TableCell align="right">{this.state.selectedHeader[5]}</TableCell>
                    <TableCell align="right">{this.state.selectedHeader[6]}</TableCell>
                    <TableCell align="right">{this.state.selectedHeader[7]}</TableCell>
                    <TableCell align="right">{this.state.selectedHeader[8]}</TableCell>
                    <TableCell align="right">{this.state.selectedHeader[9]}</TableCell>
                    <TableCell align="right">{this.state.selectedHeader[10]}</TableCell>
                    <TableCell align="right">{this.state.selectedHeader[11]}</TableCell>
                    <TableCell align="right">{this.state.selectedHeader[12]}</TableCell>
                    <TableCell align="right">{this.state.selectedHeader[13]}</TableCell>
                    <TableCell align="right">{this.state.selectedHeader[14]}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.selectedData.map((row) => {
                    // omit blank row in csv
                    if (row[3]){
                    return (<TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row[3]}
                      </TableCell>
                      <TableCell align="right">{row[5]}</TableCell>
                      <TableCell align="right">{row[6]}</TableCell>
                      <TableCell align="right">{row[7]}</TableCell>
                      <TableCell align="right">{row[8]}</TableCell>
                      <TableCell align="right">{row[9]}</TableCell>
                      <TableCell align="right">{row[10]}</TableCell>
                      <TableCell align="right">{row[11]}</TableCell>
                      <TableCell align="right">{row[12]}</TableCell>
                      <TableCell align="right">{row[13]}</TableCell>
                      <TableCell align="right">{row[14]}</TableCell>
                    </TableRow>)
                    } else {
                      return null;
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </ThemeProvider>
      </div>
    ) 
  }
}

MlbrResults.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
});

export const MlbrResultsContainer = connect(mapStateToProps, actions)(MlbrResults);

export default withStyles(styles)(withMediaQuery('(max-width:600px)')(MlbrResultsContainer));
