import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Modal from '@material-ui/core/Modal';

import { actions } from "./../reducers/reducer";

import theme from "./../theme.js";
import gt40 from './../images/art/GT40_.png';
import fisherman from './../images/art/Fisherman.png';
import mclaren from './../images/art/McLaren_P1.png';
import pencil_fish from './../images/art/pencil_fish.jpg';
//import pencil_man_bird from './../images/art/pencil_man_bird.jpg';
//import pencil_girls_face from './../images/art/pencil_girls_face.jpg';
import pencil_devils_rejects from './../images/art/pencil_devils_rejects.jpg';
import pencil_woman_hat from './../images/art/pencil_woman_hat.jpg';
import pencil_goofy from './../images/art/pencil_goofy.jpg';
import pencil_sparten from './../images/art/pencil_sparten.jpg';
import pencil_horse from './../images/art/pencil_horse.jpg';

const styles = {
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  card_root: {
//    maxWidth: 345,
    marginBottom: '10px'
  },
  media: {
    height: 140,
  },
  card_text: {
    textAlign: 'left'
  }, 
  paper: {
    padding: '5px 5px 10px 5px',
    marginBottom: '10px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  modal: {
    top:'5%',
    left:'15%',
    right:'15%',
    position: 'absolute',
    width: '70%',
    height: '90%',
//    backgroundColor: theme.palette.background.paper,
    padding: '5px 5px 1px 5px',
    outline: 'none'
  },
  modal_image: {
    width:'100%',
    height:'100%',
    objectFit: 'contain'
  },
  image: {
    cursor: 'pointer'
  }
};

class MeArt extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
  }

  state = {
    open: false,
    checked: false,
    image: undefined
  };

  // currying https://stackoverflow.com/questions/27397266/onclick-event-binding-in-react-js
  handleOpen = (image) => ()  => {
    this.setState({
      open: true,
      checked: !this.state.checked,
      image: image
    });
  };

  handleClose = () => {
    this.setState({ 
      open: false,
      image: undefined
    });
  };

  renderPaper = (image, title, text) => {
    return (
      <Paper className={this.props.classes.paper}>
        <div>
          <img src={image} width="100%" onClick={this.handleOpen(image)} className={this.props.classes.image} alt="Pencil Sketch"/>
        </div>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {text}
        </Typography>
      </Paper>
    )
  }

  render() {
    const { classes } = this.props;
    const { checked } = this.state;
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} md={4}>
                <Grid item xs={12}> 
                  {this.renderPaper(gt40, "Ford GT40", "The GT40 effort was launched by Ford Motor Company to win long-distance sports car races against Ferrari, which won every 24 Hours of Le Mans race from 1960 to 1965. The GT40 broke Ferrari's streak in 1966 and went on to win the next three annual races.")}
                </Grid>
                <Grid item xs={12}>       
                  {this.renderPaper(pencil_goofy, "Goofy", "")}
                </Grid>
                <Grid item xs={12}>       
                  {this.renderPaper(pencil_horse, "Flying Horse", "")}
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid item xs={12}>
                  {this.renderPaper(pencil_fish, "Fish Out Of Water", "")}
                </Grid>
                <Grid item xs={12}>
                  {this.renderPaper(pencil_woman_hat, "Smoking Gun", "")}
                </Grid>
                <Grid item xs={12}>
                  {this.renderPaper(pencil_devils_rejects, "The Devil's Rejects", "From the final scene as Baby Firefly, Captain Spaulding, and Otis.")}
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid item xs={12}>
                  {this.renderPaper(fisherman, "Chinese Fisherman", "A statue of this sat on my parent nightstand growing up.  It now sits on my desk as a reminder of them.")}
                </Grid>
                <Grid item xs={12}>
                  {this.renderPaper(mclaren, "McLaren F1", "On 31 March 1998, the XP5 prototype with a modified rev limiter set the Guinness World Record for the world's fastest production car, reaching 240.1 mph (386.4 km/h).")}
                </Grid>
                <Grid item xs={12}>
                  {this.renderPaper(pencil_sparten, "Sparten", "")}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div>
            <Modal
            open={this.state.open}
            onClose={this.handleClose}
          >
              {/*<Slide direction="up" in={this.state.open} mountOnEnter unmountOnExit>*/}
                <div className={classes.modal} onClick={this.handleClose}>
                  <img src={this.state.image} className={classes.modal_image} alt="Pencil Sketch"/>
                </div>
              {/*</Slide>*/}
            </Modal>
          </div>
        </ThemeProvider>
      </div>
    ) 
  }
}

MeArt.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
});

export const MeArtContainer = connect(mapStateToProps, actions)(MeArt);

export default withStyles(styles)(withMediaQuery('(max-width:600px)')(MeArtContainer));
