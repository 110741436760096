import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

//import Button from '@material-ui/core/Button';

import { actions } from "./../reducers/reducer";

//import ComingSoon from "./ComingSoon";
import sabermetrics_infographic from "../images/data-in-analysis-out-infographic.png";

import theme from "./../theme.js";

const styles = {
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  }, 
   image: {
    width: '90%',
    maxWidth: '1200px'
  }
};

class MlbrVisualization extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <ThemeProvider theme={theme}>
          {/*
          <div>
            <ComingSoon/>
          </div>
          */}
          <div>
            <img src={sabermetrics_infographic} className={classes.image} alt="Sabermetrics Infographic"/>
          </div>
        </ThemeProvider>
      </div>
    ) 
  }
}

MlbrVisualization.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
});

export const MlbrVisualizationContainer = connect(mapStateToProps, actions)(MlbrVisualization);

export default withStyles(styles)(withMediaQuery('(max-width:600px)')(MlbrVisualizationContainer));
