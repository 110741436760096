import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
//import { LOCATION_CHANGE, ConnectedRouter, syncHistoryWithStore, routerMiddleware } from "connected-react-router";
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from "history";

import * as serviceWorker from './serviceWorker';

import './index.css';

import ninjareducer from './reducers/reducer';
import { spaceDataSaga } from './sagas/saga';

import blogWildcards from "./blogConstants";

// page-components
import Home from './page-components/home/Home';
import Space from './page-components/space/Space';
import Mlbr from './page-components/mlbr/Mlbr';
import MlbNumbers from './page-components/mlbnumbers/MlbNumbers';
import Blog from './page-components/blog/Blog';
import BlogDisplay from './page-components/blog/BlogDisplay';
import Me from './page-components/me/Me';
import Resume from "./page-components/Resume";
import ThreeDim from "./page-components/ThreeDim";
import Cards from './page-components/cards/Cards';

/*
const getBaseName = () => {
  if (window.location.pathname.includes("/course") && window.location.pathname.includes("trial/course")) {
    return "/";
  }
  const courseStart = window.location.pathname.indexOf("/course");
  return window.location.pathname.substr(0, courseStart);
};

const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);

let store;
const sagaMiddleware = createSagaMiddleware()
const baseName = getBaseName();
const history = createBrowserHistory({ basename: baseName });

let middleware = [sagaMiddleware, routerMiddleware(history)];

if (1 == 1){
  store = createStoreWithMiddleware(
    ninjareducer(history),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  store = createStoreWithMiddleware(ninjareducer(history));
}
*/

const sagaMiddleware = createSagaMiddleware();
let middleware = [sagaMiddleware];
let store;
const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);
if (1 == 1){
  store = createStoreWithMiddleware(
    ninjareducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  store = createStoreWithMiddleware(ninjareducer);
}
sagaMiddleware.run(spaceDataSaga)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Route exact path="/" component={Home} />
        <Route path="/space" component={Space} />
        <Route path="/analysis-pregame" component={Mlbr} />
        <Route path="/the-numbers" component={MlbNumbers} />
        {blogWildcards.map(e => (
          <Route
            path={`/blog/${e.id}`}
            component={e.target}
          />
        ))}
        <Route path="/me" component={Me} />
        <Route path="/cards" component={Cards} />
        <Route path="/resume" component={Resume} />
        <Route path="/3D" component={ThreeDim} />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
