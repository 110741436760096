// 3rd party
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';

import MathIcon from "../../images/svg/nounproject/math";

// project redux
import { selectors, actions } from "../../reducers/reducer";

// project ui/ux
import theme from "../../theme.js";
import blogWildcards from "../../blogConstants.js";

// project components
import NavBar from "../../components/NavBar";
import Body from "../../components/Body";
import BlogList from "../../components/BlogList";

const styles = {
  root: {
    flexGrow: 1,
  },
  bottomroot: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
  main: {
    alignItems: 'center',
  },
  paragraph: {
    marginLeft: '7%',
    marginRight: '7%',
    color: '#fff',
    fontSize:'10pt',
    textAlign: 'center'
  },
  sound_image: {
    width: '70%'
  }
};


class BlogDisplay extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      key: undefined
    }
  }

  render() {
    let id = this.props.location.pathname.replace("/blog/", "");
    for (var i = 0;i < blogWildcards.length;i++){
      if (blogWildcards[i].id == id){
        this.setState({key:blogWildcards[i].key});
      }
    }
    let src = "https://docs.google.com/document/d/e/"+this.state.key+"/pub?embedded=true";
    return (
      <div>
        <ThemeProvider theme={theme}>
          <NavBar />
          <div style={{height:115}} />
          <div className={this.props.classes.root}>
            <div className={this.props.classes.main}>
              <div className={this.props.classes.paragraph}>
                <div>
                  <BlogList expanded="false" />
                </div>
                <div>
                  <iframe src={src} width="100%" height="800" title="file" frameBorder="0"></iframe>
                </div>
              </div>
            </div>
          </div>
          {this.props.mediaQuery &&
            <>
              <div style={{height:90}} />
              <BottomNavigation
                value={this.props.blogTabSelected}
                onChange={(event, newValue) => {
                  this.props.storeBlogTabSelected(newValue);
                }}
                showLabels
                className={this.props.classes.bottomroot}
              >
                <BottomNavigationAction label="Blog List" icon={<SportsBaseballIcon />} />
{/*                <BottomNavigationAction label="Visualization" icon={<LocationOnIcon />} />*/}
              </BottomNavigation>
            </>
          }
        </ThemeProvider>
      </div>
    )
  }
}

Body.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
  blogTabSelected: selectors.blogTabSelected(state)
});

export const BlogDisplayContainer = connect(mapStateToProps, actions)(BlogDisplay);

export default withStyles(styles)(withMediaQuery('(max-width:670px)')(BlogDisplayContainer));
