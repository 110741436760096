import { put, call, takeLatest, select } from "redux-saga/effects";

import {
  FETCH_SPACE_DATA,
  actions,
  selectors
} from "../reducers/reducer";

// helper functions
function fetchData(action, url) {

// SpaceX GraphQL - CORS
//  const url = "https://api.spacex.land/graphql/";

// https://github.com/r-spacex/SpaceX-API/blob/master/docs/v4/README.md
//  const url = "https://api.spacexdata.com/v4/launches/past";

  return fetch(url, {
    method: "GET",
    mode: 'cors', // no-cors, *cors, same-origin
  }).then(res => {
//      console.log(res.status);
      return res.json().then(function(data) {
//        console.log(data);
        return data;
      });
    });
}

function* spacex(action){
  const url = "https://api.spacexdata.com/v4/launches/past";
  const spacexData = yield call(fetchData, action, url);  
//  console.log(spacexData); 
  yield put(actions.storeSpacexData(spacexData));
}

function* rocketLaunchLive(action){
  const url = "https://fdo.rocketlaunch.live/json/launches/next/5";
  const rocketLaunchLiveData = yield call(fetchData, action, url);
//  console.log(rocketLaunchLiveData);
  yield put(actions.storeRocketLaunchLiveData(rocketLaunchLiveData));
}

function* spacexland(action){
//  https://api.spacex.land/rest/#/default/launchesPastQuery
  const url = "https://api.spacex.land/rest/launches-past?apoapsis_km=0&block=0&cap_serial=string&capsule_reuse=string&core_flight=0&core_reuse=string&core_serial=string&customer=string&eccentricity=0&end=&epoch=&fairings_recovered=string&fairings_recovery_attempt=string&fairings_reuse=string&fairings_reused=string&fairings_ship=string&gridfins=string&id=&inclination_deg=0&land_success=string&landing_intent=string&landing_type=string&landing_vehicle=string&launch_date_local=&launch_date_utc=&launch_success=string&launch_year=string&legs=string&lifespan_years=0&longitude=0&manufacturer=string&mean_motion=0&mission_id=string&mission_name=string&nationality=string&norad_id=0&orbit=string&payload_id=string&payload_type=string&periapsis_km=0&period_min=0&raan=0&reference_system=string&regime=string&reused=string&rocket_id=string&rocket_name=string&rocket_type=string&second_stage_block=string&semi_major_axis_km=0&ship=string&side_core1_reuse=string&side_core2_reuse=string&site_id=string&site_name_long=string&site_name=string&start=&tbd=string&tentative_max_precision=string&tentative=string&limit=5"

  const spacexlandData = yield call(fetchData, action, url);
//  console.log(rocketLaunchLiveData);
  yield put(actions.storeSpacexlandData(spacexlandData));
}

function* allSpaceData(action){
  yield call(spacex, action);
  yield call(rocketLaunchLive, action);
  yield call(spacexland, action);
}

// saga functions
export function* spaceDataSaga() {
  yield takeLatest(FETCH_SPACE_DATA, allSpaceData);
}

export default spaceDataSaga;
