// 3rd party
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import DrawingIcon from "../images/svg/nounproject/drawing";
import ResumeIcon from "../images/svg/nounproject/resume";
import ProjectIcon from "../images/svg/nounproject/project";

// project redux
import { selectors, actions } from "../reducers/reducer";

// project ui/ux
import theme from "../theme.js";

// project components
import NavBar from "../components/NavBar";
import Body from "../components/Body";
import MeResume from "../components/MeResume";

// import Snoopy from "../3dmodels/Snoopy.glb";
// import Turkey from "../3dmodels/Turkey.glb";
// import GLBFile from "../3dmodels/Baseball.glb";
import GLBFile from "../3dmodels/pumpkin.glb";
//import SnoopyDesert from "../3dmodels/seymour_park.hdr";

import * as THREE from "three";
import { Color } from "three/build/three.module.js";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";


const styles = {
  root: {
    flexGrow: 1,
  },
  //bottomroot: {
  //  width: '100%',
  //  position: 'fixed',
  //  bottom: 0,
  //  zIndex: 1250
  //},
  main: {
    alignItems: 'center',
  },
  paragraph: {
    marginLeft: '7%',
    marginRight: '7%',
    color: '#fff',
    fontSize:'10pt',
    textAlign: 'center'
  },
  //aboutme_image: {
  //  width: '70%'
  //}
};

// Canvas
const canvas = document.querySelector("canvas.webgl");
const scene = new THREE.Scene();
let renderer;
let camera;

// THIS SETS THE GRID COLOR
let color = new THREE.Color("rgb(255,0,0)");
let lineColor = new THREE.Color("rgb(0,0,255)");
let grid = new THREE.GridHelper(50,20,color,lineColor);
scene.add(grid);


class ThreeDim extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
    this.init();
  //  this.render();
  }

 //  init(); //our setup
//  render(); //the update loop

  init() {
//    console.log("start init");
    //setup the camera
    camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.25,
      20
    );
   camera.position.set(-1.8, 0.6, 2.7); 
//    console.log("camera", camera);

    // THIS SETS LIGHTING TO ILLUMINATE THE MODEL OBJECT
    const color = 0xFFFFFF;
    const intensity = 1;
    const light = new THREE.AmbientLight(color, intensity);
    scene.add(light);
    // THIS SETS THE BACKGROUND COLOR
    scene.background = new Color("black");

// THIS CREATES A WORLD AROUND THE OBJECT WITH AN HDR FILE
/*
    //load and create the environment
    new RGBELoader()
      .setDataType(THREE.UnsignedByteType)
      .load(
//        SnoopyDesert,
        "https://cdn.jsdelivr.net/gh/mrdoob/three.js@master/examples/textures/equirectangular/quarry_01_1k.hdr",
// Use this url to find .hdr files https://github.com/mrdoob/three.js/blob/master/examples/textures/equirectangular/
        function (texture) {
          const pmremGenerator = new THREE.PMREMGenerator(renderer);
          pmremGenerator.compileEquirectangularShader();
          const envMap = pmremGenerator.fromEquirectangular(texture).texture;

          scene.background = envMap; //this loads the envMap for the background
          scene.environment = envMap; //this loads the envMap for reflections and lighting

          texture.dispose(); //we have envMap so we can erase the texture
          pmremGenerator.dispose(); //we processed the image into envMap so we can stop this
        }
      );
//    console.log("RGBELoader");
*/
    // load the model
    const loader = new GLTFLoader();
    loader.load(
      GLBFile,
  //    "https://cdn.jsdelivr.net/gh/ArtIsEverything/myFirstRepository@main/house.glb",
      function (gltf) {
        scene.add(gltf.scene);
        this.render(); //render the scene for the first time
      }
    );

//    console.log("GLTFLoader", loader);

    //setup the renderer
    renderer = new THREE.WebGLRenderer({ antialias: true, canvas: canvas });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.toneMapping = THREE.ACESFilmicToneMapping; //added contrast for filmic look
    renderer.toneMappingExposure = 1;
    renderer.outputEncoding = THREE.sRGBEncoding; //extended color space for the hdr

//    console.log("renderer", renderer);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.addEventListener("change", this.render); // use if there is no animation loop to render after any changes
    controls.minDistance = .5;
    controls.maxDistance = 5;
    controls.target.set(0, 0, -0.2);
    controls.update();
//    console.log("controls", controls);

/*
    // CLIPPING
 //   var localPlane = new THREE.Plane( new THREE.Vector3( 0, - 1, 0 ), 1 );

    var globalPlane = new THREE.Plane( new THREE.Vector3( 0, -.9, 0 ), .1);
//    const helper = new THREE.PlaneHelper( globalPlane, 1, 0xffff00 );
//    scene.add( helper );
//    var globalPlane2 = new THREE.Plane( new THREE.Vector3( 0, -1.5, 0 ), -.1);

    renderer.clippingPlanes = [ globalPlane ];

    renderer.localClippingEnabled = true;
/*
    var material = new THREE.MeshPhongMaterial( {
        clippingPlanes: [ localPlane ],
        clipShadows: true
    } );
*/

    window.addEventListener("resize", this.onWindowResize);
    this.render();
  }

  onWindowResize() {
    console.log("RESIZE");
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);

    this.render();
  }

  //

  render() {
//    console.log("In Render");
//    console.log("scene", scene);
//    console.log("camera", camera);
//    console.log("In render - renderer", renderer);
    renderer.render(scene, camera);
    return("");
  }
/*
  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <NavBar />
          <div style={{height:115}} />
          <div className={this.props.classes.root}>
            <div className={this.props.classes.main}>
              <div className={this.props.classes.paragraph}>
                <div>
                  <MeResume />
                </div>
              </div>
            </div>
          </div>
          {this.props.mediaQuery &&
            <>
              <div style={{height:90}} />
              <BottomNavigation
                showLabels
                className={this.props.classes.bottomroot}
              >
                <BottomNavigationAction label="Resume" icon={<ResumeIcon />} />
              </BottomNavigation>
            </>
          }
        </ThemeProvider>
      </div>
    )
  }
  */
}

Body.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
});

export const ThreeDimContainer = connect(mapStateToProps, actions)(ThreeDim);

export default withStyles(styles)(withMediaQuery('(max-width:670px)')(ThreeDimContainer));
