import MLBIcon from '@material-ui/icons/SportsBaseball';
import TwelvthManIcon from '@material-ui/icons/SportsFootball';
import FaceToFriendIcon from '@material-ui/icons/SentimentVerySatisfied';
import RssIcon from '@material-ui/icons/RssFeed';
import BrewineryIcon from '@material-ui/icons/LocalBar';
import NowcastingIcon from '@material-ui/icons/Cloud';

import ftfThumbnail from './../images/me/ftf_thumbnail.png';
import rssThumbnail from './../images/me/rss_thumbnail.png';
import bwyThumbnail from './../images/me/bwy_thumbnail.png';
import blankThumbnail from './../images/me/blank_video_thumbnail.png';

const sections = [
// MLB + R
  { icon: MLBIcon,
    name: 'Black Cloud Ninja',
    year: '2015-2022',
    key: 'mlb',
    color: '#B21F35',
    desc: "Analysis tool built in R, allowing for the breakdown of baseball by every major sabermetrics stat.  Allows weighting the importance of stats and eventually leveraging AI to determine the optimal weighting to provide value in the fantasy sports world.",
    problem: "In daily fantasy sports, the goal is typically to either get to one of the top couple spots in a pool of lineups, or to play a 50/50 game where you only need to beat fifty percent of the lineups for a small payout.  When competing against the casual player who knows a fair amount about the players, it should be somewhat easy to apply a little bit of math and leverage sabermetrics to gain the edge necessary to be in the top fifty percent on a consistent basis.  Many of the successful players are already doing this type of thing.  ",
    solution: "I started by manually pulling readily available data regarding day to day, and season to season stats into Excel and writing formulas to weight sabermetrics categories. This would produce a team of players with the highest score and define the lineup for the day. This soon became unmanageable and limiting in what I could do with Excel. After looking into better technologies and languages I chose R as a superior platform to do these types of calculations. Throughout 2015 and 2016 I was able to build an application which took manually sourced data daily combined with an algorithm to produce the optimal lineup within salary and rule constraints. The final variable was the proper weights on many, many sabermetrics statistics, that over time would produce the best results. While I started looking into AI solutions, I also awaited ruling on the legal status of daily fantasy across states. In late 2020 I decided to pick this back up. In moving the R server to a web server I'm able to produce and display resulting calculations much more easily. Automation of the data gathering and finally using learning algorithms to find ideal sabermetric weighings will finally produce a fully functional application.",
    technology: "Front End: React/Redux, Javascript, HTML/JSX, CSS  Back End: R, Node",
    learned: "I learned an incredible amount about how far you can push the limits of Excel.  Diving into R with zero prior knowledge shifted my mind into how data mining and manipulation works.  Reading many books on sabermetrics gave me a different view on baseball and odds.  Finally my first inroads into machine learning have been an adventure which I still can't claim to have mastered, but is certainly a very interesting area of exploration.",
    thumbnail: blankThumbnail,
    video: undefined },
// face to friend
  { icon: FaceToFriendIcon,
    name: 'Face To Friend',
    year: '2011-12',
    key: 'ftf', 
    color: '#0079E7',
    desc: "I set out to build a social network business around connecting individuals with similar interests.  This leveraged Facebook’s Graph API to pull and analyze comparative data.  I gained a lot of experience in technical areas, as well as humanistic tendencies, and marketing efforts.",
    problem: "Social networks from MySpace to Facebook had spent years connecting individuals with friends, acquaintances, co-workers, or childhood friends online.  There was also an emerging acceptance for online dating.  There were social clubs locally where you could sign up to play kickball and share in a specific shared interest.  What was missing was a single location to find friends with many shared interests to just chat with online or build real and lasting friendships with.",
    solution: "Facebook has done an amazing job of scaling to reach board audiences from college to senior ages and everything in between. They had also collected large amounts of data on these individuals' likes and interests, and through their Graph API made it available to developers. By having users safely share access to their Facebook data, I was able to find connections between people that they wouldn't have even easily discovered in their friends. Included in this was their physical location, if they chose to share that, so they could meet people and share real life activities. Starting out anonymously, it allowed users to slowly build trust through casual conversation to the point of sharing their true identity and eventually transitioning to actual Facebook friends.",
    technology: "Front End: Javascript, jQuery, lowpro.jquery, jQuery.ui, JSON, Facebook Javascript SDK, HTML5, canvas, CSS, development of Titan Javascript controls.  Back End: LAMP, PHP, MySQL, Apache",
    learned: "I learned a great deal about componentized front end architectures and libraries, backend languages, and application hosting. I also put a great deal of focus into UI/UX to provide a unique but familiar interface from what Facebook users were accustomed to using. In creating an LLC business, setting up business accounts, and handling taxes I gained a great deal of business experience. The greatest challenge and learning experience was in marketing the application and gaining users. This was a potentially expensive, possibly low cost guerilla marketing, or at times creative exploration of bot technology to create a community from nothing.",
    thumbnail: ftfThumbnail,
    video: 'yVxIu5br1-0' },
// 12th man
  { icon: TwelvthManIcon,
    name: '12th Man',
    year: '2012-13',
    key: '12m',
    color: '#16DD36',
    desc: "Constructed an algorithm to assess fantasy football players available, combined with the attributes you value in forming your team, and a polished UI, to suggest the players you should draft.  This project led to me joining Ruckus Gaming and expanding this feature adding in daily salary to the algorithm, to form the “Victron”.",
    problem: "Each late summer, fall, and winter people all over America draft their fantasy team, and then face the challenges of players not performing well, getting hurt, and unknowns becoming standouts in the fantasy world. Most people know their home team well, maybe the division they play in a bit, and perhaps some top players beyond that. But drafting the superstars isn't what creates consistency in the fantasy world. Being able to adjust throughout the season and put in the sleepers no one else saw makes the difference. A key in this is having more information. Perhaps your league is set up to advantage receivers, or maybe running backs. You know what you need, but often finding the players that meet that requirement is a challenge.",
    solution: "To help the average fantasy football player, I built an application that allowed the user to use a polished UI to weight the categories in things like receiving yards, rushing yards, touchdowns, points over last x games or seasons, injury history, and many other types of attributes.  Using Yahoo Fantasy Football's API it then pulled down your specific league, looked for who was available, and made suggestions on who you should draft based on the weighing you gave.  It could also analyze your team and the teams they were playing that week, competitor defensive strengths against your players, and suggest the optimal starting lineup. ",
    technology: "Front End: Javascript, jQuery, HTML5, CSS  Back End: Ruby, Apache, Yahoo Fantasy Football API",
    learned: "The tool I built really did meet the need I set out to address.  I used it successfully getting to the championship game in my league before falling victim to the better team.  Long term the application needed yearly maintenance to adjust to any changes to the API or auth.  I didn't implement for other platforms, like ESPN, which would have also been useful as more of my leagues moved over there.  In putting it out there I was found by a team looking to start a daily fantasy company and was hired on to lead their front end team.  I eventually adapted this to be a key differentiator for the qpplication we built, trying to reach more of the casual audience in daily fantasy sports.  ",
    thumbnail: blankThumbnail,
    video: undefined },
// nowcasting
  { icon: NowcastingIcon,
    name: 'Nowcasting',
    year: '2015',
    key: 'now',
    color: '#FFFFFF',
    desc: "Focusing on my interest in meteorology, I built a site to start to combine the basic elements involved in predicting areas for mesocyclone development.  I combined real time weather station data and historical AI analysis of similar conditions, to construct a real time view of favorable conditions.",
    problem: "In the study of mesocyclone activity there is so much to be gathered from the data around temperature, pressure, humidity, wind speed and direction, topography, and more at various altitudes from ground level to upper atmosphere readings.  A storm chaser will often start by plotting this all manually each morning to try to understand where weather patterns are most likely to develop, and throughout the day adjust to attempt an intercept later in the day with a tornado.  While there is so much science and data backing this effort, there is also a great deal of experience and manual effort to pull it all together.  Most of the time, it doesn't work out.",
    solution: "I attempted to build a very base level application to make predictions on where mesocyclones would occur, based on the incredible amount of real time data and based on historical setups and outcomes. While I had no real intention of solving this with some groundbreaking algorithm, I found the challenge of even attempting it to be a lot of fun. I was able to plot data visually, find dry lines, and identify large areas that deserved further interest or evaluation. I used some basic machine learning strategies to look at historical setups where major tornados did occur and try to find the common indicators. The correlations were inconclusive and probably needed a lot more data or likely many more geographical points of this data on very short timelines to further refine and see what could be found as predictable indicators. With so many variables it's still questionable if this is even truly possible to put a dot on a map more than seconds or minutes at most in advance.",
    technology: "Front End: Angular, Javascript, Google Maps, CSS, HTML  Back End: Firebase, Node, Python",
    learned: "This was an interesting project to learn a bit more about AI and machine learning.  Following tutorials and modifying them for my needs, I was impressed I could get results from them.  While what happened in the middle is still very unclear to me, its a start into an emerging technology I would like to spend a lot more time with.  I also gained a bit more experience with Angular and found Firebase to be an exceptionally easy and valuable tool to reduce the need to build complicated backend systems and databases.  It really allowed me to focus on the algorithms and front end user experience.",
    thumbnail: blankThumbnail,
    video: undefined },
// read some stuff
  { icon: RssIcon, 
    name: 'Read Some Stuff',
    year: '2009-11',
    key: 'rss', 
    color: '#FF7435', 
    desc: "Developed an advanced RSS reader that allowed you to form your own boards around interests, share them with other users, and search existing feeds.  The ultimate goal was to connect people based on shared interests, and potentially market goods and services to those interests. ",
    problem: "RSS feeds at the time, and to a degree even now, don't have mainstream non-techie awareness. Knowing how to pull together a bunch of quality feeds on topics is for anyone a considerable effort. What are the best websites on a topic, do they have a feed, is it updated regularly, what tools do I need to read them. All of these factors have kept RSS feeds from being as useful as they should be to the common person.",
    solution: "I set out to build a platform that allowed users to import feeds, label them by topic, organize them in boards and themes for their own purposes.  Allowed for viewing the content behind the headline to view the entire article.  When creating a new panel and adding feeds, the user can enter their own, or choose from existing feeds entered by other users, shown to them anonymously.  In doing this it allows those who aren't aware of rss feeds of their own, to leverage a community to find and define the best feeds by topic.  Next steps would be to upvote the best feeds found.  My goal was to use the shared interest in certain topics to start connecting individuals for further discussion on the topic in a lightweight social network.  Long term this could be supplemented with ads or other ways to monetize based on self proclaimed interests of the users or small communities that form.",
    technology: "Front End: Javascript, jQuery, HTML5, CSS.  Back End: LAMP, PHP, MySQL, Apache ",
    learned: "Aside from the technology in building it and experience coding, a big takeaway was around how to start a community.  Connecting a perceived need to a real audience is a challenge.  Particularly difficult when the base topic is RSS feed aggregators which isn't something the common person may be looking for.  Needed a better plan about getting the application out to an audience, not yet touching on how to serve the first few users in a network and giving them a positive experience while the community grows.  The final stage which I didn't get any further than some google ads into, would be working on a plan to monetize. ",
    thumbnail: rssThumbnail,
    video: 'EQqfjB7v2d8' },
// socal brewinery
  { icon: BrewineryIcon,
    name: 'SoCal Brewinery',
    year: '2008-09',
    key: 'bwy',
    color:'#FFCB35',
    desc: "SoCal Brewinery was a fun project with my wife to promote a community around fine wines and craft beers in the southern California area.",
    problem: "We thought it would be fun to create some fun characters, promote local wineries and breweries, build a community to give reviews, and encourage a general discussion.  San Diego is one of the best beer towns in America featuring a very hoppy profile and a ton of craft breweries.  An hour away in Temecula, there were a huge number of wineries, not quite Napa, but still noteworthy and just a lot of fun to explore.",
    solution: "I built a website to host reviews, articles, calendars, and other news from the southern California area.  We developed and produced quite a few videos featuring characters we made up, some my wifes characters, some animated, all combined in a fun way to build a community around.",
    technology: "Front End: Javascript, CSS, HTML  Back End: PHP, Apache, MySQL",
    learned: "Gained some experience coding in PHP, UI/UX, and hosting an application. Performance was an issue at times and I had to troubleshoot and identify root causes. Was a good experience for me at that point in my career. We learned a lot around video production and editing. How to create and promote fun content, and how to build a community.",
    thumbnail: bwyThumbnail,
    video: '0GihbaYJ-I4' },
];

export default sections;
