import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { actions } from "./../reducers/reducer";

import Paper from '@material-ui/core/Paper';

const styles = {
  video_player: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0
  },
  paper_container: {
    margin: '2% 2% 2% 2%',
  },
  video_container: {
    margin: '2% 2% 5% 2%',
    position: 'relative',
    paddingBottom: '56.25%'
  },
}


class YouTubeVideo extends PureComponent {
  static propTypes = { 
    id: PropTypes.string.isRequired,
  };  

  componentDidMount = () => {
    // On mount, check to see if the API script is already loaded

    if (!window.YT) { // If not, load the script asynchronously
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = this.loadVideo;

      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    } else { // If script is already there, load the video directly
      this.loadVideo();
    }   
  };  
    
  loadVideo = () => {
    const { id } = this.props;
  
    // the Player object is created uniquely based on the id in props
    this.player = new window.YT.Player(`youtube-player-${id}`, {
      videoId: id, 
      playerVars: {
        'autoplay': 1,
        'controls': 0,
        'modestbranding':1,
        'showinfo':0,
        'showsearch':0,
        'rel':0,
      },
      events: {
        onReady: this.onPlayerReady,
      },  
    }); 
  };  

  onPlayerReady = event => {
    event.target.playVideo();
  };  

  render = () => {
    const { id } = this.props;
    return (
      <Paper className={this.props.classes.paper_container}>
        <div className={this.props.classes.video_container}>
          <div id={`youtube-player-${id}`} className={this.props.classes.video_player} />
        </div>
      </Paper>
    );  
  };  
}

YouTubeVideo.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
});

export const YouTubeVideoContainer = connect(mapStateToProps, actions)(YouTubeVideo);

export default withStyles(styles)(withMediaQuery('(max-width:600px)')(YouTubeVideoContainer));
