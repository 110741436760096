// https://v3.material-ui.com/customization/themes/
// https://material-ui.com/customization/color/

import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

// All the following keys are optional.
// We try our best to provide a great default value.
const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
//      main: '#B26037',
      main: '#2976fe'
    },
    secondary: {
//      main: '#e91e63',
      main: '#ff0b00',
    },
    white: {
      main: '#ffffff',
    },
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    h5: {
      color: '#6db9ff',
      margin: '10px 0px 5px 0px'
    },
    body2: {
      color: '#cfd4d8',
    },
    iframe: {
      borderColor: '#000000',
      backgroundColor: '#000000'
    }
  },
});

export default theme;
