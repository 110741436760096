import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MathIcon(props) {
    return (
      <SvgIcon {...props} viewBox="0 0 100 125" >
        <path d="M87.51465,86.15332V13.8457A8.856,8.856,0,0,0,78.668,5H21.332a8.856,8.856,0,0,0-8.84668,8.8457V86.15332A8.85689,8.85689,0,0,0,21.332,95H78.668A8.85689,8.85689,0,0,0,87.51465,86.15332ZM74.14648,84.03418H58.168a2.5,2.5,0,0,1,0-5H74.14648a2.5,2.5,0,0,1,0,5Zm0-18.93066H68.65723v5.48925a2.5,2.5,0,0,1-5,0V65.10352H58.168a2.5,2.5,0,0,1,0-5h5.48926V54.61426a2.5,2.5,0,0,1,5,0v5.48926h5.48925a2.5,2.5,0,0,1,0,5Zm.5625-18.71875h-22.706l-9.85938,35.8125a2.4996,2.4996,0,0,1-4.82031,0L30.76563,58.37793H25.291a2.5,2.5,0,1,1,0-5H32.6709a2.50032,2.50032,0,0,1,2.41015,1.83691L39.7334,72.11426l7.9541-28.89258a2.50034,2.50034,0,0,1,2.41016-1.83691H74.709a2.5,2.5,0,0,1,0,5Zm2.96558-17.6626a1.43839,1.43839,0,0,1-1.43823,1.43823H23.76392a1.4384,1.4384,0,0,1-1.43824-1.43823V16.26611a1.43824,1.43824,0,0,1,1.43824-1.43823H76.23633a1.43823,1.43823,0,0,1,1.43823,1.43823Z" />
      </SvgIcon>
    );
  }
