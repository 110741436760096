import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Button from '@material-ui/core/Button';

import { actions } from "./../reducers/reducer";

import theme from "./../theme.js";
import resume from "./../documents/scott-gay-resume-04052024.pdf";

const styles = {
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  }, 
};

class MeResume extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
  }

  checkMobile() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile){
      window.location.href = resume;
    }
  }

  render() {
    this.checkMobile();
    return (
      <div>
        <ThemeProvider theme={theme}>
          <div>
            <Button variant="contained" color="primary" href={resume} target="blank">
              View Full PDF Version
            </Button>
          </div>
          <div height="10px">&nbsp;</div>
          <div>
            <object data={resume} type="application/pdf" width="80%" height="1000px">
              <embed src={resume} type="application/pdf" />
            </object>
          </div>
        </ThemeProvider>
      </div>
    ) 
  }
}

MeResume.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
});

export const MeResumeContainer = connect(mapStateToProps, actions)(MeResume);

export default withStyles(styles)(withMediaQuery('(max-width:600px)')(MeResumeContainer));
