import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

import TreeView from '@material-ui/lab/TreeView';
//import ExpandMoreIcon from '@material-ui/icons-material/ExpandMore';
//import ChevronRightIcon from '@material-ui/icons-material/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

import { actions } from "./../reducers/reducer";

import theme from "./../theme.js";

import blogWildcards from "./../blogConstants.js";

const styles = {
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  }, 
  table: {
    minWidth: 650,
  },
  tree: {
    textAlign: 'left',
    fontSize: '1rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0.00938em'
  },
  treeItem: {
    color: '#ffffff',
  },
  title: {
    color: '#ff0b00'
  },
  subtitle: {
    color: theme.palette.primary.main
  },
  blogHeader: {
    textAlign: 'left',
    fontSize: '12pt',
    fontSize: '1rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0.00938em'
  },
  article: {
    marginLeft: '35px'
  },
  link: {
    textDecoration: 'none'
  }
};

class BlogList extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;

    let expandList = [];
    if (this.props.expanded == "true"){
      expandList = ['1','2','3','4','5','6','7','8'];
    }
    this.state = {
      expandedList: expandList 
    };
  }

  handleToggle(event, nodeIds) {
    this.setState({expandedList: nodeIds});
  }

  renderArticleLink(article) {
    return(
      <Link to={`/blog/${article.id}`} className={this.props.classes.link}>
        <div className={this.props.classes.article}>
          <span className={this.props.classes.title}>{article.title}</span>
          <span className={this.props.classes.subtitle}>&nbsp;|&nbsp;{article.subtitle}</span>
        </div>
      </Link>
    );
  }

  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <div className={this.props.classes.blogHeader}>
            Blog: 10th Inning
          </div>
          <div>
            <TreeView
              aria-label="file system navigator"
              className={this.props.classes.tree}
              expanded={this.state.expandedList}
              onNodeToggle={this.handleToggle.bind(this)}
              sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
            >
              <TreeItem nodeId="1" label="September 2021" className={this.props.classes.treeItem}>
                {blogWildcards.map((article) => (
                  article.id !== "list" && article.date === "September 2021" && this.renderArticleLink(article)
                ))}
              </TreeItem>
              <TreeItem nodeId="3" label="November 2021">
                {blogWildcards.map((article) => (
                  article.id !== "list" && article.date === "November 2021" && this.renderArticleLink(article)
                ))}
              </TreeItem>
              <TreeItem nodeId="4" label="February 2022">
                {blogWildcards.map((article) => (
                  article.id !== "list" && article.date === "February 2022" && this.renderArticleLink(article)
                ))}
              </TreeItem>
              <TreeItem nodeId="5" label="May 2022">
                {blogWildcards.map((article) => (
                  article.id !== "list" && article.date === "May 2022" && this.renderArticleLink(article)
                ))}
              </TreeItem>
              <TreeItem nodeId="6" label="December 2022">
                {blogWildcards.map((article) => (
                  article.id !== "list" && article.date === "December 2022" && this.renderArticleLink(article)
                ))}
              </TreeItem>
              <TreeItem nodeId="7" label="January 2023">
                {blogWildcards.map((article) => (
                  article.id !== "list" && article.date === "January 2023" && this.renderArticleLink(article)
                ))}
              </TreeItem>
              <TreeItem nodeId="8" label="January 2024">
                {blogWildcards.map((article) => (
                  article.id !== "list" && article.date === "January 2024" && this.renderArticleLink(article)
                ))}
              </TreeItem>
            </TreeView>
          </div>
        </ThemeProvider>
      </div>
    ) 
  }
}

BlogList.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
});

export const BlogListContainer = connect(mapStateToProps, actions)(BlogList);

export default withStyles(styles)(withMediaQuery('(max-width:600px)')(BlogListContainer));
