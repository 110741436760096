import Blog from './page-components/blog/Blog';
import BlogDisplay from './page-components/blog/BlogDisplay';

const blogWildcards = [
  {
    id: "list",
    date: "", 
    title: "",
    subtitle: "",
    target: Blog, 
    key: ""
  },
  {
    id: "where-it-all-started-a-love-for-baseball-data-and-opportunity", 
    date: "September 2021",
    title: "Where It All Started",
    subtitle: "a love for baseball, data, and opportunity",
    target: BlogDisplay, 
    key: "2PACX-1vTKaOsUtvJDECOinjvlFFJ1EPaMa34EGVOAr2-JjDUPCrapSuYBX_BWB7jl1xSK5o2wZGYMwIoaiR8h"
  },
  {
    id: "recommended-resources-just-starting-out-look-no-further",
    date: "September 2021",
    title: "Recommended Resources",
    subtitle: "just starting out, look no further",
    target: BlogDisplay,
    key: "2PACX-1vSm4uhr6YCPl-SBZBdNnPEOvNwOmz_SyhWBoHqL6_QXyKN2soUiWjqGS0TP34sSoaa_XpbUAtfarf-N"
  },
  {
    id: "predictive-backtesting-v3-logical-weighting-of-stats-based-on-draftkings-scoring", 
    date: "November 2021",
    title: "Predictive Backtesting v3.0",
    subtitle: "logical weighting of stats based on DK scoring",
    target: BlogDisplay, 
    key: "2PACX-1vSxja-rgc4aQRD8Vp2eo3aKGUhfEoh4PjYvXFfFTtYLBry3Q-3OvmFUkHcNv8pIebNvDmxQXLkePWSJ"
  },
  {
    id: "aws-vs-linode-comparing-micro-instances",
    date: "February 2022",
    title: "AWS vs Linode",
    subtitle: "comparing micro instances",
    target: BlogDisplay,
    key: "2PACX-1vRGyyCUgf-nVfFbEk6a5XTZivfNOYoVU3HHRaPNOYJ_XuP7lN6hTh7_PFT83FHVP5zxxRpFMLAdWf4g"
  },
  {
    id: "2021-analysis-second-half",
    date: "May 2022",
    title: "2021 Analysis",
    subtitle: "second half",
    target: BlogDisplay,
    key: "2PACX-1vSvR3Zg9XyY-J1Xz42b3MTQJV0yK_pHJlKG--V4Ug36Sa7GEqIa_Fkr0QXZnhlegJh-Ui1Dllrbn-so"
  },
  {
    id: "books-2022-year-in-review",
    date: "December 2022",
    title: "Books 2022",
    subtitle: "year in review",
    target: BlogDisplay,
    key: "2PACX-1vT_0GK9Gg4OXFnGKETm2kA8WHv-yIy5XT3FUkZNp6dnqt6UpNoDZ8uQ3ckNViBiaiYs35TSaSYwzgHB"
  },
  {
    id: "books-2023-year-in-review",
    date: "January 2023",
    title: "Books 2023",
    subtitle: "year in review",
    target: BlogDisplay,
    key: "2PACX-1vSSuHK7y6iVnDMsTFozDTdEUN91sxulgCFsA9q9zHS1vVGgKSaw8BxvjCj_C95youSb0KX2zjguG9Gi"
  },
  {
    id: "books-2024-year-in-review",
    date: "January 2024",
    title: "Books 2024",
    subtitle: "year in review",
    target: BlogDisplay,
    key: "2PACX-1vRbLA2IwsY9V3NuWraOAcEvUvZ4NxP1hv54UdX6fCfuW0tC1umsLex7JrCBHOv0DxCvRQx71nokumCw"
  },

];

export default blogWildcards;
