// 3rd party
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
// import Gist from "react-gist";
// import Gist from 'super-react-gist'
import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';

import MathIcon from "../../images/svg/nounproject/math";

// project redux
import { selectors, actions } from "../../reducers/reducer";

// project ui/ux
import theme from "../../theme.js";

// project components
import NavBar from "../../components/NavBar";
import Body from "../../components/Body";
import MlbrSabermetrics from "../../components/MlbrSabermetrics";
import MlbrResults from "../../components/MlbrResults";
import MlbrVisualization from "../../components/MlbrVisualization";

// import notebook_adjusted from '../../notebooks/numbers_adjusted.html')

const styles = {
  root: {
    flexGrow: 1,
  },
  bottomroot: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
  main: {
    alignItems: 'center',
  },
  paragraph: {
    marginLeft: '7%',
    marginRight: '7%',
    color: '#fff',
    fontSize:'10pt',
    textAlign: 'center'
  },
  sound_image: {
    width: '70%'
  }
};


class MlbNumbers extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <NavBar />
          <div style={{height:115}} />
          <div className={this.props.classes.root}>
            <div className={this.props.classes.main}>
              <div className={this.props.classes.paragraph}>
                <div>
                  {this.props.mlbnumbersTabSelected === 0 &&
                    <MlbrResults />
                  }
                  {this.props.mlbnumbersTabSelected === 1 &&
                    <iframe src="https://blackcloudconcepts.github.io/cdn/2021_MLB_Prediction_Runs.html" width="100%" height="1000" title="file" frameBorder="0"></iframe>
                  }
                  {this.props.mlbnumbersTabSelected === 2 &&
                    <iframe src="https://blackcloudconcepts.github.io/cdn/Consistency_Analysis.html" width="100%" height="1000" title="file" frameBorder="0"></iframe>
                  }
                </div>
              </div>
            </div>
          </div>
          {this.props.mediaQuery &&
            <>
              <div style={{height:90}} />
              <BottomNavigation
                value={this.props.mlbnumbersTabSelected}
                onChange={(event, newValue) => {
                  this.props.storeMlbnumbersTabSelected(newValue);
                }}
                showLabels
                className={this.props.classes.bottomroot}
              >
                <BottomNavigationAction label="Daily Lineup" icon={<MathIcon />} />
                <BottomNavigationAction label="2021 Prediction Outcomes" icon={<SportsBaseballIcon />} />
                <BottomNavigationAction label="2021 Player Consistency" icon={<SportsBaseballIcon />} />
{/*                <BottomNavigationAction label="Visualization" icon={<LocationOnIcon />} />*/}
              </BottomNavigation>
            </>
          }
        </ThemeProvider>
      </div>
    )
  }
}

Body.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
  mlbnumbersTabSelected: selectors.mlbnumbersTabSelected(state)
});

export const MlbNumbersContainer = connect(mapStateToProps, actions)(MlbNumbers);

export default withStyles(styles)(withMediaQuery('(max-width:670px)')(MlbNumbersContainer));
