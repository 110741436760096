// 3rd party
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import CardistryIcon from "../../images/svg/nounproject/cardistry";
import MemorizeIcon from "../../images/svg/nounproject/memorize";

// project redux
import { selectors, actions } from "../../reducers/reducer";

// project ui/ux
import theme from "../../theme.js";

// project components
import NavBar from "../../components/NavBar";
import Body from "../../components/Body";
import CardsCardistry from "../../components/CardsCardistry";
import CardsMemorization from "../../components/CardsMemorization";
import CardsDecks from "../../components/CardsDecks";

const styles = {
  root: {
    flexGrow: 1,
  },
  bottomroot: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
  main: {
    alignItems: 'center',
  },
  paragraph: {
    marginLeft: '7%',
    marginRight: '7%',
    color: '#fff',
    fontSize:'10pt',
    textAlign: 'center'
  },
  sound_image: {
    width: '70%'
  }
};


class Cards extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <NavBar />
          <div style={{height:115}} />
          <div className={this.props.classes.root}>
            <div className={this.props.classes.main}>
              <div className={this.props.classes.paragraph}>
                <div>
                  {this.props.cardsTabSelected === 0 &&
                    <CardsMemorization />
                  }
                  {this.props.cardsTabSelected === 1 &&
                    <CardsCardistry />
                  }
                  {this.props.cardsTabSelected === 2 &&
                    <CardsDecks />
                  }
                </div>
              </div>
            </div>
          </div>
          {this.props.mediaQuery &&
            <>
              <div style={{height:90}} />
              <BottomNavigation
                value={this.props.cardsTabSelected}
                onChange={(event, newValue) => {
                  this.props.storeCardsTabSelected(newValue);
                }}
                showLabels
                className={this.props.classes.bottomroot}
              >
                <BottomNavigationAction label="Memorization" icon={<MemorizeIcon />} />
                <BottomNavigationAction label="Cardistry" icon={<CardistryIcon />} />
{/*                <BottomNavigationAction label="Decks" icon={<LocationOnIcon />} />*/}
              </BottomNavigation>
            </>
          }
        </ThemeProvider>
      </div>
    )
  }
}

Body.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
  cardsTabSelected: selectors.cardsTabSelected(state)
});

export const CardsContainer = connect(mapStateToProps, actions)(Cards);

export default withStyles(styles)(withMediaQuery('(max-width:670px)')(CardsContainer));
