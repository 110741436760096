import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    flexGrow: 1,
  },
  main: {
    alignItems: 'center',
  },
  paragraph: {
    marginLeft: '7%',
    marginRight: '7%',
    color: '#fff',
    fontFamily: '"Trebuchet MS", Helvetica, sans-serif',
    fontSize:'10pt'
  }
};

function Body(props) {
  const { classes } = props;
    return(
        <div className={classes.root}>
          <div className={classes.main}>
            <p className={classes.paragraph}>
                CENTOS8 x - Test Publish Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur bibendum lorem ipsum, a vestibulum ante interdum in. Sed aliquam condimentum arcu eget gravida. Vivamus scelerisque ante massa, at scelerisque risus placerat sed. Aliquam fringilla diam a augue congue, vel varius lacus egestas. Mauris a ullamcorper sapien. Ut finibus sapien felis, ut ultrices odio consequat quis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Cras at varius mauris. Ut massa massa, posuere non ante vel, pharetra interdum est. Suspendisse potenti. Ut pretium lacinia ex a tempor. Praesent pellentesque turpis sit amet mattis eleifend.

                Quisque sed urna vel risus molestie gravida ut id nisl. Praesent eu blandit orci, vitae rutrum risus. Sed vel arcu metus. Vestibulum sapien nunc, elementum non leo sed, vehicula cursus lectus. Nunc fringilla ex vel mauris sodales, sed aliquet dolor luctus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec mi diam, feugiat a enim a, fringilla aliquam orci. Phasellus sit amet auctor ante. Nullam auctor, erat malesuada pellentesque imperdiet, sapien leo rutrum elit, placerat pellentesque est neque eget massa. Praesent iaculis sem at mauris dictum, id laoreet lacus malesuada. Suspendisse potenti. In purus ex, pulvinar eu augue eget, egestas faucibus risus. Sed tempor mauris ante, quis varius libero viverra sed. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas vestibulum sem at lectus aliquam, eu feugiat urna molestie. Nulla sed lacus eget risus facilisis luctus.

                Cras et vulputate purus. Ut id ligula nisl. Mauris iaculis turpis ac lorem mattis, vitae fringilla sapien faucibus. Quisque sit amet mattis magna, sit amet porttitor massa. Aenean consequat dui in mauris scelerisque finibus. Etiam quis porta orci. Integer venenatis, mauris in sodales pellentesque, leo diam finibus ligula, vitae accumsan lacus ante mattis mi. Nunc ut urna tempus mi hendrerit sagittis eu vitae velit. Sed quis ipsum augue. Morbi id dui vel sapien porttitor dapibus. Praesent vel condimentum tellus. Etiam a lacus id nunc scelerisque condimentum. Sed hendrerit sapien et sapien tincidunt suscipit sit amet interdum lorem. Aliquam eget augue ac ipsum consequat rhoncus. Praesent ullamcorper pulvinar pharetra. Morbi auctor ligula sit amet magna dictum, et tristique tellus ornare.

                Proin sapien ligula, ornare a diam in, maximus convallis est. Duis vulputate dignissim nisl sed vestibulum. Vivamus urna leo, accumsan non convallis non, interdum in tortor. In mollis ante id turpis dignissim, elementum suscipit sem tincidunt. Sed lacus sem, porta sit amet fermentum vel, cursus vel quam. Maecenas magna purus, viverra eu ipsum at, dapibus pretium turpis. Curabitur justo dolor, efficitur vel accumsan vitae, gravida eu tortor. Pellentesque non quam a risus venenatis euismod a facilisis nisl. Etiam et arcu tincidunt, dignissim leo a, aliquam tellus.

                Donec lacinia, felis in convallis malesuada, dui neque scelerisque risus, eu aliquet turpis elit at tellus. Morbi eros mi, laoreet a ante ac, lacinia gravida ipsum. Ut sit amet diam auctor, pharetra sapien in, lobortis enim. Vestibulum dolor enim, consequat eu posuere tincidunt, tristique et diam. Aenean molestie eros eget ligula elementum commodo. Quisque scelerisque dictum enim, a consectetur neque lobortis ultrices. Pellentesque maximus risus sodales erat ultricies semper. Nam lacinia sem id faucibus vulputate. Cras vel eleifend risus. Suspendisse congue dolor lobortis ultrices dictum. Mauris consectetur malesuada malesuada. Mauris ullamcorper tellus ac tellus semper, eu varius purus ullamcorper.
            </p>
          </div>
        </div>
    )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Body);

