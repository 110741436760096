import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RocketIcon(props) {
    return (
      <SvgIcon {...props} viewBox="0 0 736 918.75" >
        <path d="M347 440l-40 41 -24 79 84 145 -197 -197 23 -163c-71,-53 -132,-113 -168,-195 23,-13 45,-29 65,-49 12,-11 22,-24 31,-36 129,78 207,175 291,273 -68,-119 -149,-243 -277,-294 3,-6 6,-12 9,-18l1 -3c86,36 146,97 201,170l163 -24 197 197 -145 -84 -79 24 -41 41c11,25 19,47 21,66l-18 18c-44,-47 -104,-108 -179,-182 -10,-11 -27,5 -16,16 75,75 135,134 182,179l-18 18c-18,-3 -41,-11 -66,-22zm389 295c-75,-53 -224,-125 -264,-165 -32,-32 -45,-64 -44,-90l20 -20c126,120 132,114 12,-12l21 -21c25,-1 57,12 90,45 39,39 112,189 165,263zm-720 -607c-22,-60 -19,-107 -6,-120 11,-10 55,-13 114,7l-1 1c-3,7 -7,13 -11,20l-4 -2c-15,-5 -67,-22 -84,-18 28,12 53,24 77,37 -8,11 -17,22 -27,31 -18,18 -37,33 -58,44z" />
      </SvgIcon>
    );
  }
