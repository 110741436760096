// 3rd party
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import DrawingIcon from "../../images/svg/nounproject/drawing";
import ResumeIcon from "../../images/svg/nounproject/resume";
import ProjectIcon from "../../images/svg/nounproject/project";

// project redux
import { selectors, actions } from "../../reducers/reducer";

// project ui/ux
import theme from "../../theme.js";

// project components
import NavBar from "../../components/NavBar";
import Body from "../../components/Body";
import MeResume from "../../components/MeResume";
import MeArt from "../../components/MeArt";
import MeProjects from "../../components/MeProjects";

const styles = {
  root: {
    flexGrow: 1,
  },
  bottomroot: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 1250
  },
  main: {
    alignItems: 'center',
  },
  paragraph: {
    marginLeft: '7%',
    marginRight: '7%',
    color: '#fff',
    fontSize:'10pt',
    textAlign: 'center'
  },
  aboutme_image: {
    width: '70%'
  }
};


class Me extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <NavBar />
          <div style={{height:115}} />
          <div className={this.props.classes.root}>
            <div className={this.props.classes.main}>
              <div className={this.props.classes.paragraph}>
                <div>
                  {this.props.meTabSelected === 0 &&
                    <MeProjects /> 
                  }
                  {this.props.meTabSelected === 1 &&
                    <MeArt />
                  }
                  {this.props.meTabSelected === 2 &&
                    <MeResume />
                  }
                </div>
              </div>
            </div>
          </div>
          {this.props.mediaQuery &&
            <>
              <div style={{height:90}} />
              <BottomNavigation
                value={this.props.meTabSelected}
                onChange={(event, newValue) => {
                  this.props.storeMeTabSelected(newValue);
                }}
                showLabels
                className={this.props.classes.bottomroot}
              >
                <BottomNavigationAction label="Projects" icon={<ProjectIcon />} />
                <BottomNavigationAction label="Art" icon={<DrawingIcon />} />
                <BottomNavigationAction label="Resume" icon={<ResumeIcon />} />
              </BottomNavigation>
            </>
          }
        </ThemeProvider>
      </div>
    )
  }
}

Body.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
  meTabSelected: selectors.meTabSelected(state)
});

export const MeContainer = connect(mapStateToProps, actions)(Me);

export default withStyles(styles)(withMediaQuery('(max-width:670px)')(MeContainer));
