// 3rd party
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import RocketIcon from "../../images/svg/nounproject/rocket";

// project redux
import { selectors, actions } from "../../reducers/reducer";

// project ui/ux
import theme from "../../theme.js";

// project components
import NavBar from "../../components/NavBar";
import Body from "../../components/Body";
import SpaceLaunch from "../../components/SpaceLaunch";
import SpaceCompanies from "../../components/SpaceCompanies";


const styles = {
  root: {
    flexGrow: 1,
  },
  bottomroot: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
  main: {
    alignItems: 'center',
  },
  paragraph: {
    marginLeft: '7%',
    marginRight: '7%',
    color: '#fff',
    fontSize:'10pt',
    textAlign: 'center'
  },
  mars: {
    width: '70%'
  }
};

class Space extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
    this.props.fetchSpaceData();
  }

  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <NavBar />
          <div style={{height:115}} />
          <div className={this.props.classes.root}>
            <div className={this.props.classes.main}>
              <div className={this.props.classes.paragraph}>
                <div>
                  {this.props.spaceTabSelected === 0 &&
                    <SpaceLaunch />
                  }
                  {this.props.spaceTabSelected === 1 &&
                    <SpaceCompanies />
                  }
                  {this.props.spaceTabSelected === 2 &&
                    <span>Simulation Coming Soon</span>
                  }
                </div>
              </div>
            </div>
          </div>
          {this.props.mediaQuery &&
            <>
              <div style={{height:90}} />
              <BottomNavigation
                value={this.props.spaceTabSelected}
                onChange={(event, newValue) => {
                  this.props.storeSpaceTabSelected(newValue);
                }}
                showLabels
                className={this.props.classes.bottomroot}
              >
                <BottomNavigationAction label="Launches" icon={<RocketIcon fontSize="small"/>} />
{/*                <BottomNavigationAction label="Companies" icon={<FilterHdrIcon />} /> */}
{/*                <BottomNavigationAction label="Simulation" icon={<LocationOnIcon />} />*/}
              </BottomNavigation>
            </>
          }
        </ThemeProvider>
      </div>
    )
  }
}

Body.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
//  spacexData: selectors.spacexData(state),
//  rocketLaunchLiveData: selectors.rocketLaunchLiveData(state),
  spaceTabSelected: selectors.spaceTabSelected(state)
});

export const SpaceContainer = connect(mapStateToProps, actions)(Space);

export default withStyles(styles)(withMediaQuery('(max-width:670px)')(SpaceContainer));
