import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ProjectIcon(props) {
    return (
      <SvgIcon {...props} viewBox="0 0 100 125" >
        <path d="M96.5,67.5L85.8,48.1c-2.3-4.1-7.4-5.6-11.5-3.4c-1.1,0.6-1.9,1.4-2.6,2.3V16.3c0-7.6-6.2-13.8-13.8-13.8H16.9    C9.3,2.5,3.2,8.7,3.2,16.3v40.9c0,7.6,6.1,13.7,13.7,13.7h23.3l3.7,6.7l-4.8-1.4c-3.9-1.1-8.2,0.7-10,4.3c-1.1,2.2-1.3,4.7-0.4,7    c0.9,2.3,2.6,4.1,4.9,5l12.5,4.8c0.3,0.1,0.7,0.2,1,0.2c1.2,0,2.2-0.7,2.7-1.8c0.6-1.5-0.2-3.1-1.6-3.7l-12.5-4.8    c-0.8-0.3-1.3-0.9-1.6-1.7c-0.3-0.8-0.2-1.6,0.1-2.3c0.6-1.2,2-1.8,3.3-1.4l8.4,2.4c1.6,0.5,3.4-0.1,4.4-1.4    c1.1-1.3,1.2-3.1,0.4-4.6L31.5,43c-0.7-1.3-0.2-3,1.1-3.8c0.7-0.4,1.4-0.4,2.1-0.2c0.7,0.2,1.3,0.7,1.7,1.3l13.2,24.1    c0.8,1.4,2.5,1.9,3.9,1.1c1.4-0.8,1.9-2.5,1.1-3.9l-1.8-3.3c-0.7-1.3-0.2-3,1.1-3.8c0.7-0.4,1.4-0.4,2.1-0.2    c0.7,0.2,1.3,0.7,1.7,1.3l3.4,6.3c0.8,1.4,2.5,1.9,3.9,1.1c1.4-0.8,1.9-2.5,1.1-3.9L64.3,56c-0.4-0.7-0.4-1.4-0.2-2.1    c0.2-0.7,0.7-1.3,1.3-1.7c1.3-0.7,3-0.2,3.8,1.1l1.6,2.9c0,0,0,0,0,0l1.8,3.3c0.8,1.4,2.5,1.9,3.9,1.1c1.4-0.8,1.9-2.5,1.1-3.9    l-1.7-3c0-0.1-0.1-0.2-0.1-0.3c-0.7-1.3-0.2-3,1.1-3.8c1.3-0.7,3-0.2,3.8,1.1l10.7,19.4c0.8,1.4,2.5,1.9,3.9,1.1    C96.7,70.6,97.2,68.9,96.5,67.5z M62.7,47.2c-1.2,0.7-2.2,1.6-3,2.7c-0.6-0.4-1.4-0.7-2.1-0.9c-2.2-0.6-4.5-0.4-6.5,0.7    c-0.9,0.5-1.6,1.1-2.2,1.7l-7.6-13.8c-1.1-2-2.9-3.4-5.1-4.1c-2.2-0.6-4.5-0.4-6.5,0.7c-4.1,2.3-5.6,7.4-3.3,11.5l10.7,19.4H16.9    c-4.4,0-8-3.6-8-8V16.3c0-4.4,3.6-8,8-8h40.9c4.4,0,8,3.6,8,8v29.9C64.8,46.3,63.7,46.6,62.7,47.2z" />
      </SvgIcon>
    );
  }
