import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DrawingIcon(props) {
    return (
      <SvgIcon {...props} viewBox="0 0 100 125" >
        <path d="M87.7,85.8c-0.4-0.8-0.9-1.2-1.2-1.5c-1.3-0.9-2.2-1.1-2.8-1.3l-2-0.6l-4-1.1l-8-2.1c-2.6-0.7-5.3-1.4-7.8-2.1     c-2-0.6-4-1.2-5.7-2c0.4-0.1,0.8-0.3,1.2-0.4c2.4-0.7,5-1.3,7.7-1.8c5.3-1,10.7-1.8,16-2.5c1.3-0.2,2.2-1.3,2.1-2.6     c-0.1-1.3-1.3-2.3-2.6-2.1c-5.5,0.6-10.9,1.2-16.4,2.1c-2.8,0.5-5.5,1-8.3,1.8c-1.5,0.5-2.8,0.8-4.6,1.9     c-0.4,0.3-0.9,0.7-1.5,1.5c-0.5,0.7-0.9,2.3-0.4,3.3c0.8,2,1.8,2.4,2.5,2.9c3,1.8,5.5,2.5,8.3,3.4c2.7,0.9,5.3,1.6,7.9,2.3     l7.8,2.3l0.4,0.1c-2.1,0.4-4.3,0.7-6.5,1c-5.3,0.6-10.7,1-16.1,1.3c-6.7,0.3-13.5,0.4-20.2,0.3l-9.9,7.5     c10.2,0.4,20.4,0.1,30.6-0.6c5.5-0.4,11-1,16.5-1.8c2.8-0.4,5.5-0.9,8.3-1.5c1.4-0.3,2.8-0.7,4.3-1.2c0.8-0.3,1.5-0.6,2.5-1.2     c0.5-0.4,1.1-0.6,2-2.2c0.2-0.4,0.3-0.9,0.3-1.5C88.1,86.8,87.9,86.2,87.7,85.8z" /><path d="M37.4,78.2l25.3-54.7l-6.4-3L31.6,73.9c-0.3,0.7-0.3,1.4-0.1,2.1c0.3,0.7,0.8,1.2,1.4,1.5v0c-0.6,0.9-1.2,1.7-2.1,2.3     l-6.2,4.7l-8.2-3.8l-0.4-7.7C16,72,16.2,71,16.5,70c0.4,0.2,0.7,0.3,1.1,0.3c0.3,0,0.7-0.1,1-0.2c0.7-0.2,1.2-0.7,1.5-1.4     l24.8-53.4l-6.4-3L13.1,66.9c-0.9,2-1.3,4.2-1.2,6.3l1.2,21.2c0,0.8,0.5,1.5,1.2,1.8c0.3,0.1,0.6,0.2,0.9,0.2     c0.4,0,0.9-0.1,1.2-0.4l16.9-12.8C35.1,81.9,36.4,80.2,37.4,78.2z"/><path d="M65.7,17.1c0.7-1.4,0.7-3,0.2-4.5c-0.5-1.5-1.6-2.6-3-3.3L49.1,3c-2.9-1.4-6.3-0.1-7.7,2.8l-1.7,3.7L64,20.8L65.7,17.1z"/><path d="M22.8,72c0.3,0.7,0.8,1.2,1.4,1.6l1,0.4c0.7,0.3,1.4,0.4,2.1,0.1c0.7-0.2,1.2-0.8,1.5-1.4l24.7-53.4l-6-2.8L22.9,69.9     C22.6,70.6,22.5,71.3,22.8,72z"/>
      </SvgIcon>
    );
  }
