import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { ThemeProvider } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { actions } from "./../reducers/reducer";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';
//import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import BackIcon from "./../images/svg/nounproject/back";
import ImageCodeBackground from "./../images/code_background.jpg";
/*
import MLBIcon from '@material-ui/icons/SportsBaseball';
import TwelvthManIcon from '@material-ui/icons/SportsFootball';
import FaceToFriendIcon from '@material-ui/icons/SentimentVerySatisfied';
import RssIcon from '@material-ui/icons/RssFeed';
import BrewineryIcon from '@material-ui/icons/LocalBar';
import NowcastingIcon from '@material-ui/icons/Cloud';
*/

import sections from './../content/MeProjectContent.js';
import YouTubeVideo from './YouTubeVideo';
import theme from "./../theme.js";

// import ftfThumbnail from './../images/me/ftf_thumbnail.png';

const styles = {
  paper: {
    padding: '6px 16px',
  },
  video_player: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0
  },
  paper_container: {
    padding: '10px'
  },
  video_container: {
    margin: '2% 2% 5% 2%',
  },
  card_root: {
//    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  project_text: {
    margin: '15px 10px 15px 10px',
    textAlign: 'left'
  },
  modal: {
    top:'20%',
    left:'15%',
    right:'15%',
    position: 'absolute',
    width: '70%',
    height: '90%',//    backgroundColor: theme.palette.background.paper,    padding: '5px 5px 1px 5px',
    outline: 'none'
  },
  modal_image: {
    width:'100%',
    height:'100%',
    objectFit: 'contain'
  },
  panel_image: {
    width: '45%',
    marginLeft: '5%',
  },
  panel_video: {
    float: 'left',
    position: 'relative',
  },
  left_section: {
    backgroundColor: '#222222',
    paddingTop: '1%',
    marginBottom: '4px',
    fontFamily: 'Sigmar One',
  },
  play_button: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  play_icon: {
    color: '#fb4848',
    fontSize: '7vw'
  },
  thumb: {
    width: '100%',
    height: 'auto'
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grid_root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(2),
      width: '100%',
    },
  },
  panel: {
    cursor: 'pointer',
    fontFamily: 'Sigmar One',
    border: '2px solid #777777',
//    opacity: '.75',
    backgroundColor: '#222222',
    padding: '5px'
  },
  panel_icon: {
    fontSize: '16vw'
  },
  panel_name: {
    fontSize: 'min(3.8vw, 200%)'
  },
  back: {
//    fontSize: '12pt'
    color: '#ffffff'
  },
  card_text: {
    textAlign: 'left',
    fontSize: '0.875rem',
    color: 'rgba(255, 255, 255, 0.7)'
  },
  header: {
    backgroundColor: '#222222'
  }
};
/*
const sections = [
  { icon: FaceToFriendIcon, 
    name: 'Face To Friend', 
    key: 'ftf', 
    color: '#0079E7',
    desc: "Created LLC to build a social network business around connecting individuals with similar interests.  Leveraged Facebook’s Graph API to pull and analyze comparative data.  Gained a lot of experience in technical areas, as well as humanistic tendencies, and marketing efforts.", 
    video: 'yVxIu5br1-0' },
  { icon: RssIcon, 
    name: 'Read Some Stuff', 
    key: 'rss', 
    color: '#FF7435', 
    desc: "Before the age of social media, developed an advanced RSS reader that allowed you to form your own boards around interests, share them, and search existing feeds.  The ultimate goal was to connect people based on shared interests, and potentially market goods and services to those interests. ", 
    video: 'EQqfjB7v2d8' },
  { icon: TwelvthManIcon, 
    name: '12th Man', 
    key: '12m', 
    color: '#16DD36', 
    desc: "Constructed an algorithm to assess fantasy football players available, combined with the attributes you value in forming your team, and a polished UI, to suggest the players you should draft.  This project led to me joining Ruckus Gaming and expanding this feature adding in daily salary to the algorithm, to form the “Victron”.", 
    video: undefined },
  { icon: MLBIcon, 
    name: 'MLB + R', 
    key: 'mlb', 
    color: '#B21F35', 
    desc: "Analysis tool built in R, allowing for the breakdown of baseball by every major sabermetrics stat.  Allows weighting the importance of stats and eventually leveraging AI to determine the optimal weighting to provide value in the fantasy sports world.", 
    video: undefined },
  { icon: BrewineryIcon, 
    name: 'SoCal Brewinery', 
    key: 'bwy', 
    color:'#FFCB35', 
    desc: "Some Description", 
    video: '0GihbaYJ-I4' },
  { icon: NowcastingIcon, 
    name: 'Nowcasting', 
    key: 'now', 
    color: '#FFFFFF', 
    desc: "Focusing on my interest in meteorology, I built a site to start to combine the basic elements involved in predicting areas for mesocyclone development.  Combined real time weather station data and historical AI analysis of similar conditions, to construct a real time view of favorable conditions.", 
    video: undefined },
];
*/
class MeProjects extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
  }

  state = {
    open: false,
    checked: false,
    selected: undefined,
    videoId: undefined
  };

  // currying https://stackoverflow.com/questions/27397266/onclick-event-binding-in-react-js
  handleOpen = (section) => ()  => {
    this.setState({
      open: true,
      checked: !this.state.checked,
      selected: this.state.selected,
      videoId: section.video
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      selected: this.state.selected,
      videoId: undefined
    });
  };

  handleSelect = (section) => () => {
    this.setState({
      open: false,
      selected: section,
      videoId: undefined
    });
  }

  resetSelectedProject = () => () => {
    this.setState({
      open: false,
      selected: undefined,
      videoId: undefined
    });
  }

  renderSectionPanel = (section) => {
    const CustomIcon = section.icon;
    return (
      <Paper variant="outlined" square onClick={this.handleSelect(section)} className={this.props.classes.panel}>
        <div><CustomIcon style={{fontSize:"16vw",color:section.color}} /></div>
        <div className={this.props.classes.panel_name}>{section.name}</div>
      </Paper>
    )
  }

  renderProject = (section) => {
    const CustomIcon = section.icon;
    return (
      <Grid container spacing={1}>
        <Grid xs={2} style={{textAlign: 'left'}}>
          <IconButton onClick={this.resetSelectedProject()} className={this.props.classes.back}>
            <BackIcon style={{fontSize:'2.5rem'}}/>
          </IconButton>
        </Grid>
        <Grid xs={10}></Grid>
        <Grid xs={12}>
          <Paper className={this.props.classes.paper_container}>
            <Grid container>
              <Grid xs={6} className={this.props.classes.left_section}>
                <CustomIcon style={{fontSize:"10vw",color:section.color}} />
                <div style={{fontSize: 'min(3vw, 40px)'}}>
                  {section.name}
                </div>
                <div style={{fontSize: 'min(2vw, 20px)'}}>
                  {section.year}
                </div>
              </Grid>
              <Grid xs={6}>
                <div className={this.props.classes.panel_video}>
                  {section.video !== undefined &&
                  <IconButton aria-label="open" aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleOpen(section)} className={this.props.classes.play_button}>
                    <PlayCircleFilledRoundedIcon className={this.props.classes.play_icon} />
                  </IconButton>
                  }
                  <img src={section.thumbnail} className={this.props.classes.thumb} alt="thumbnail" />
                </div>
              </Grid>
              <Grid xs={12} className={this.props.classes.project_text}>
                <Typography variant="h5" component="p">Overview</Typography>
                <Typography variant="body2" component="p">{section.desc}</Typography>
                <Typography variant="h5" component="p">Problem</Typography>
                <Typography variant="body2" component="p">{section.problem}</Typography>
                <Typography variant="h5" component="p">Solution</Typography>
                <Typography variant="body2" component="p">{section.solution}</Typography>
                <Typography variant="h5" component="p">Learned</Typography>
                <Typography variant="body2" component="p">{section.learned}</Typography>
                <Typography variant="h5" component="p">Technology</Typography>
                <Typography variant="body2" component="p">{section.technology}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }

  renderVideo = (id) => {
    return (
      <YouTubeVideo id={id} />
    )
  }

  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Card className={this.props.classes.card_root}>
            <CardMedia
              className={this.props.classes.media}
              image={ImageCodeBackground}
              title="Projects"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Personal Projects and Applications
              </Typography>
              <Grid container spacing={1}>
                  {this.state.selected === undefined &&
                    <Grid xs={12}>
                      <Typography variant="body1" className={this.props.classes.card_text}>
                        Always involved in a creative idea.  Always trying out a new technology.  I've spent well over a decade on side projects enjoying the brainstorming process, and working through design and implementation.  Each has been a rewarding experience in a new way, and always many lessons learned.
                      </Typography>
                    </Grid>            
                  } 
                  {this.state.selected === undefined &&
                    sections.map((section, index) => (
                      <Grid xs={6} className={this.props.classes.grid_root}>
                        {this.renderSectionPanel(section)}
                      </Grid>
                    ))
                  }
                  {this.state.selected !== undefined &&
                    <Grid xs={12}>
                      {this.renderProject(this.state.selected)}
                    </Grid>
                  }
              </Grid>
              {this.state.videoId &&
              <div>
                <Modal
                  open={this.state.open}
                  onClose={this.handleClose}
                >
                  {/*<Slide direction="up" in={this.state.open} mountOnEnter unmountOnExit>*/}
                    <div className={this.props.classes.modal} onClick={this.handleClose}>
                      {this.renderVideo(this.state.videoId)}
                    </div>
                  {/*</Slide>*/}
                </Modal>
              </div>
              }
            </CardContent>
          </Card>
        </ThemeProvider>
      </div>
    ) 
  }
}

MeProjects.propTypes = {
  classes: PropTypes.object.isRequired
};

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
};

const mapStateToProps = state => ({
});

export const MeProjectsContainer = connect(mapStateToProps, actions)(MeProjects);

export default withStyles(styles)(withMediaQuery('(max-width:600px)')(MeProjectsContainer));

