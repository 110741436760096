import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ResumeIcon(props) {
    return (
      <SvgIcon {...props} viewBox="0 0 512 640" >
        <path d="M308.7,39.2l-.1-.1a9.881,9.881,0,0,0-7.2-3,4.1,4.1,0,0,0-1.1.1H139.1a52.024,52.024,0,0,0-52,52v336a52.024,52.024,0,0,0,52,52H372.9a52.024,52.024,0,0,0,52-52V159.3a10.327,10.327,0,0,0-2.9-7.1Zm2.7,30.9L391,149.5H343.4a32.094,32.094,0,0,1-32-32ZM372.9,456H139.1a32.094,32.094,0,0,1-32-32V88a32.094,32.094,0,0,1,32-32H291.4v61.5a52.024,52.024,0,0,0,52,52h61.5V424A32.03,32.03,0,0,1,372.9,456Z"/><path d="M158.7,314.6H283.3a10,10,0,1,0,0-20H158.7a10,10,0,0,0,0,20Z"/><path d="M353.3,370.3H158.7a10,10,0,0,0,0,20H353.3a10,10,0,0,0,0-20Z" /><path d="M158.7,314.6H283.3a10,10,0,1,0,0-20H158.7a10,10,0,0,0,0,20Z"/><path d="M353.3,370.3H158.7a10,10,0,0,0,0,20H353.3a10,10,0,0,0,0-20Z"/><path d="M198.7,179.4A36.7,36.7,0,1,0,162,142.7,36.724,36.724,0,0,0,198.7,179.4Zm0-53.3A16.7,16.7,0,1,1,182,142.8,16.73,16.73,0,0,1,198.7,126.1Z"/><path d="M158.7,254.1a10.029,10.029,0,0,0,10-10v-8a30,30,0,1,1,60,0v8a10,10,0,0,0,20,0v-8a50,50,0,1,0-100,0v8A10.029,10.029,0,0,0,158.7,254.1Z"/>
      </SvgIcon>
    );
  }
